import axios from 'axios'
import store from '@/store'
import Request from 'axios-request-handler'

export class MonthlyApiService {
  getQuickstartConfiguration () {
    return new Promise(function (resolve, reject) {
      axios
        .get(
          process.env.VUE_APP_QS_URL + '/public/rest/quickstart/configuration',
          {
            params: {
              apiKey: store.getters.apiKey
            }
          }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  getAllocationStrategies () {
    return new Promise(function (resolve, reject) {
      axios
        .get(
          process.env.VUE_APP_QS_URL + '/public/rest/quickstart/allocations',
          {
            params: {
              apiKey: store.getters.apiKey
            }
          }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  async getQuickstartRequest () {
    return new Promise(async function (resolve, reject) {
      let requestHash = store.getters.requestHash
      await axios
        .get(
          process.env.VUE_APP_QS_URL +
            '/public/rest/quickstart/request/' +
            requestHash
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  updateContact () {
    return new Promise(function (resolve, reject) {
      axios
        .post(process.env.VUE_APP_QS_URL + '/public/rest/quickstart/contact', {
          apiKey: store.getters.apiKey,
          bucketId: store.getters.getBucketId,
          leadState: store.getters.getLeadState,
          requestId: store.getters.requestId,
          requestHash: store.getters.requestHash,
          clientEmail: store.getters.clientEmail,
          clientPhoneNumber: store.getters.clientPhoneNumber,
          retireeName: store.getters.clientName
        })
    })
  }
  startQuickstart () {
    return new Promise(function (resolve, reject) {
      let areYouMarried = (store.getters.isMarried === 1) ? 'yes' : 'no'
      let quickstartRequest = store.getters.quickstartRequest
      let leadOwner = (store.getters.isInternalTool && quickstartRequest.leadOwner === 0) ? store.getters.internalUserId : quickstartRequest.leadOwner
      let url = (store.getters.isInternalTool) ? '/public/rest/quickstart/request?internal=true' : '/public/rest/quickstart/request'
      axios
        .post(process.env.VUE_APP_QS_URL + url, {
          apiKey: store.getters.apiKey,
          bucketId: store.getters.getBucketId,
          leadState: store.getters.getLeadState,
          leadSource: store.getters.getLeadSource,
          leadOwner: leadOwner,
          requestId: store.getters.requestId,
          requestHash: store.getters.requestHash,
          step: store.getters.step,
          areYouMarried: areYouMarried,
          allocationId: store.getters.investmentProfile,
          clientEmail: store.getters.clientEmail,
          clientPhoneNumber: store.getters.clientPhoneNumber,
          householdMonthlyRetirementSpending: store.getters.spending,
          retireeName: store.getters.clientName,
          retireeNamePrefix: store.getters.clientNamePrefix,
          retireeAge: store.getters.clientAge,
          retireeRetirementAge: store.getters.clientRetirementAge,
          retireeLifeExpectancy: store.getters.clientLifeExpectancy,
          retireeSocialSecurityMonthlyPia:
            store.getters.clientSocialSecurityBenefitAmount,
          retireePensionMonthlyIncome: store.getters.clientMonthlyPension,
          retireeOtherMonthlyIncome: store.getters.clientOtherMonthlyIncome,
          spouseName: store.getters.partnerName,
          spouseNamePrefix: store.getters.partnerNamePrefix,
          spouseAge: store.getters.partnerAge,
          spouseRetirementAge: store.getters.partnerRetirementAge,
          spouseLifeExpectancy: store.getters.partnerLifeExpectancy,
          spouseSocialSecurityMonthlyPia:
            store.getters.partnerSocialSecurityBenefitAmount,
          spousePensionMonthlyIncome: store.getters.partnerMonthlyPension,
          spouseOtherMonthlyIncome: store.getters.partnerOtherMonthlyIncome,
          taxDeferredBalance: store.getters.taxDeferredBalance,
          planTaxDeferredBalance: store.getters.planTaxDeferredBalance,
          taxDeferredContribution: store.getters.taxDeferredContribution,
          taxableBalance: store.getters.taxableBalance,
          planTaxableBalance: store.getters.planTaxableBalance,
          annuityBalance: store.getters.annuityBalance,
          taxExemptBalance: store.getters.taxExemptBalance,
          planTaxExemptBalance: store.getters.planTaxExemptBalance,
          nonInvestmentBalance: store.getters.nonInvestmentBalance,
          inheritedIraBalance: store.getters.inheritedIraBalance,
          importReferenceId: store.getters.importReferenceId
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  sendReport () {
    return new Promise(function (resolve, reject) {
      let areYouMarried = (store.getters.isMarried === 1) ? 'yes' : 'no'
      axios
        .post(process.env.VUE_APP_QS_URL + '/public/rest/quickstart/report', {
          apiKey: store.getters.apiKey,
          bucketId: store.getters.getBucketId,
          leadState: store.getters.getLeadState,
          leadSource: store.getters.getLeadSource,
          requestId: store.getters.requestId,
          requestHash: store.getters.requestHash,
          crmContactId: store.getters.getCrmContactId,
          step: store.getters.step,
          areYouMarried: areYouMarried,
          allocationId: store.getters.investmentProfile,
          clientEmail: store.getters.clientEmail,
          clientPhoneNumber: store.getters.clientPhoneNumber,
          householdMonthlyRetirementSpending: store.getters.spending,
          retireeName: store.getters.clientName,
          retireeNamePrefix: store.getters.clientNamePrefix,
          retireeAge: store.getters.clientAge,
          retireeRetirementAge: store.getters.clientRetirementAge,
          retireeLifeExpectancy: store.getters.clientLifeExpectancy,
          retireeSocialSecurityMonthlyPia:
            store.getters.clientSocialSecurityBenefitAmount,
          retireePensionMonthlyIncome: store.getters.clientMonthlyPension,
          retireeOtherMonthlyIncome: store.getters.clientOtherMonthlyIncome,
          spouseName: store.getters.partnerName,
          spouseNamePrefix: store.getters.partnerNamePrefix,
          spouseAge: store.getters.partnerAge,
          spouseRetirementAge: store.getters.partnerRetirementAge,
          spouseLifeExpectancy: store.getters.partnerLifeExpectancy,
          spouseSocialSecurityMonthlyPia:
            store.getters.partnerSocialSecurityBenefitAmount,
          spousePensionMonthlyIncome: store.getters.partnerMonthlyPension,
          spouseOtherMonthlyIncome: store.getters.partnerOtherMonthlyIncome,
          taxDeferredBalance: store.getters.taxDeferredBalance,
          planTaxDeferredBalance: store.getters.planTaxDeferredBalance,
          taxDeferredContribution: store.getters.taxDeferredContribution,
          taxableBalance: store.getters.taxableBalance,
          planTaxableBalance: store.getters.planTaxableBalance,
          annuityBalance: store.getters.annuityBalance,
          taxExemptBalance: store.getters.taxExemptBalance,
          planTaxExemptBalance: store.getters.planTaxExemptBalance,
          nonInvestmentBalance: store.getters.nonInvestmentBalance,
          inheritedIraBalance: store.getters.inheritedIraBalance
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  downloadReport (clientName) {
    return new Promise(function (resolve, reject) {
      axios
        .get(process.env.VUE_APP_QS_URL + '/public/quickstart/report.pdf', {
          responseType: 'blob',
          params: {
            key: store.getters.requestHash,
            name: clientName,
            r: true,
            q: true
          }
        })
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          var filenameId = (clientName !== '') ? clientName : store.getters.importReferenceId

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'retirementquickstart_' + filenameId + '.pdf')
          document.body.appendChild(fileLink)

          fileLink.click()
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  startQuickOptimize () {
    return new Promise(function (resolve, reject) {
      store.dispatch('resetQuickstartResults')
      axios
        .get(process.env.VUE_APP_QS_URL + '/public/rest/quickstart/results', {
          params: {
            requestHash: store.getters.requestHash,
            r: true,
            q: true
          }
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  startOptimize () {
    return new Promise(function (resolve, reject) {
      store.dispatch('resetQuickstartResults')
      axios
        .get(process.env.VUE_APP_QS_URL + '/public/rest/quickstart/results', {
          params: {
            requestHash: store.getters.requestHash,
            r: true
          }
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
  getQuickResults () {
    return new Promise(function (resolve, reject) {
      var requestInstance = new Request(
        process.env.VUE_APP_QS_URL + '/public/rest/quickstart/results',
        {
          params: {
            requestHash: store.getters.requestHash,
            r: false,
            q: true
          },
          lockable: true,
          cancelable: false,
          errorHandler: (error, method) => {
            reject(error)
          }
        }
      )
      requestInstance
        .poll(1500)
        .get(response => {
          store.dispatch('updateQuickstartResults', response.data.payload)
          if (typeof response.data.payload !== 'undefined' && response.data.payload.complete) {
            return false
          } else {
            return true
          }
        })
        .then(resolve())
    })
  }
  getResults () {
    return new Promise(function (resolve, reject) {
      var requestInstance = new Request(
        process.env.VUE_APP_QS_URL + '/public/rest/quickstart/results',
        {
          params: {
            requestHash: store.getters.requestHash,
            r: false
          },
          lockable: true,
          cancelable: false,
          errorHandler: (error, method) => {
            reject(error)
          }
        }
      )
      requestInstance
        .poll(1500)
        .get(response => {
          store.dispatch('updateQuickstartResultsAdvanced', response.data.payload)
          if (typeof response.data.payload !== 'undefined' && response.data.payload.complete) {
            return false
          } else {
            return true
          }
        })
        .then(resolve())
    })
  }
  getSensitivityResults () {
    return new Promise(function (resolve, reject) {
      var requestInstance = new Request(
        process.env.VUE_APP_QS_URL + '/public/rest/quickstart/sensitivity',
        {
          params: {
            requestHash: store.getters.requestHash
          },
          lockable: true,
          cancelable: false,
          errorHandler: (error, method) => {
            reject(error)
          }
        }
      )
      requestInstance
        .poll(1500)
        .get(response => {
          if (typeof response.data.payload !== 'undefined' && response.data.payload.complete) {
            store.dispatch('updateSensitivityResultsAdvanced', response.data.payload)
            return false
          } else {
            return true
          }
        })
        .then(resolve())
    })
  }
  getQuickSensitivityResults () {
    return new Promise(function (resolve, reject) {
      var requestInstance = new Request(
        process.env.VUE_APP_QS_URL + '/public/rest/quickstart/sensitivity',
        {
          params: {
            requestHash: store.getters.requestHash,
            q: true
          },
          lockable: true,
          cancelable: false,
          errorHandler: (error, method) => {
            reject(error)
          }
        }
      )
      requestInstance
        .poll(1500)
        .get(response => {
          if (typeof response.data.payload !== 'undefined' && response.data.payload.complete) {
            store.dispatch('updateSensitivityResults', response.data.payload)
            return false
          } else {
            return true
          }
        })
        .then(resolve())
    })
  }
}
