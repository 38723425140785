<template>
  <section class='hero'>
    <div id='widget-container'>
      <div class="hero-head">
        <Banner v-if="getCurrentPage == 0 && !hideBanner"/>
      </div>
      <div class="hero-body">
        <navigation v-if="getCurrentPage != 1"></navigation>
        <slot />
      </div>
      <div class="hero-foot">
        <Footer />
      </div>
    </div>
    <b-modal :active="emailReportModalActive" :width="640" scroll="keep" :component="EmailForm" :onCancel="() => this.setEmailReportModalActive(false)">
    </b-modal>
    <b-modal :active="downloadReportModalActive" :width="640" scroll="keep" :component="DownloadForm" :onCancel="() => this.setDownloadReportModalActive(false)">
    </b-modal>
    <b-modal :active="getMoreTeaserModalActive" :width="400" scroll="keep" :component="GetMoreTeaserForm" :onCancel="() => this.setGetMoreTeaserModalActive(false)">
    </b-modal>
    <b-modal :active="pleaseWaitModalActive" :width="400" scroll="keep" :can-cancel="false" >
      <generic-modal message="Thank you for your patience. It may take a few moments to complete your request.  This dialog will close automatically when your request is complete."></generic-modal>
    </b-modal>
  </section>
</template>

<script>
import Banner from '@/components/Banner'
import Footer from '@/components/Footer'
import Navigation from '@/components/Navigation'
import EmailForm from '@/components/EmailForm'
import DownloadForm from '@/components/DownloadForm'
import GenericModal from '@/components/GenericModal'
import GetMoreTeaserForm from '@/components/GetMoreEmailTeaserForm'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      EmailForm,
      DownloadForm,
      GetMoreTeaserForm,
      GenericModal
    }
  },
  components: {
    Banner,
    Footer,
    Navigation,
    EmailForm,
    GetMoreTeaserForm,
    GenericModal
  },
  props: {
  },
  computed: {
    ...mapGetters([
      'getImageUrl',
      'getCurrentPage'
    ]),
    ...mapState([
      'emailReportModalActive',
      'downloadReportModalActive',
      'getMoreTeaserModalActive',
      'pleaseWaitModalActive',
      'hideBanner'
    ])
  },
  methods: {
    ...mapActions({
      setEmailReportModalActive: 'setEmailReportModalActive',
      setDownloadReportModalActive: 'setDownloadReportModalActive',
      setPleaseWaitModalActive: 'setPleaseWaitModalActive',
      setGetMoreTeaserModalActive: 'setGetMoreTeaserModalActive'
    })
  },
  mounted () {
  }
}
</script>

<style scoped>
.hero-body {
  padding: 1rem 1.5rem 3rem 0rem;
}

</style>
