<template>
    <div class="savingssection panel" id="savings-panel">
      <p v-show="!hidePanelHeading" class="columnsuperhead">Step 3:</p>
      <p v-show="!hidePanelHeading" class="panel-heading columnhead">Retirement Savings</p>
      <div class="panel-block">
        <section class="content-section-full">
          <div class="columns">
            <div class="column">
              <div id="plan-data-card" class="card">
                <div class="card-header">
                  <p class="card-header-title">Your {{getPlanName}}</p>
                </div>
                <div class="card-content">
                  <b-field label="Total retirement savings">
                    <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your tax deferred savings balance" v-model.number="planTaxDeferredBalanceInput" data-vv-name="plan tax deferred balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
                  </b-field>
                  <span v-show="errors.has('plan tax deferred balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('plan tax deferred balance', 'step3') }}</span>
                  <b-field label="Annual retirement contributions">
                    <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your tax deferred annual contributions" v-model.number="taxDeferredContributionInput" data-vv-name="tax deferred contribution" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
                  </b-field>
                  <span v-show="errors.has('tax deferred contribution', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('tax deferred contribution', 'step3') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!showOutsideSavings" class="columns">
            <div class="column text-center"><button type="button" @click="toggleShowOutsideSavings" class="button is-secondary additionalsavings-btn" href="#">Click to include additional outside savings</button></div>
          </div>
          <div v-else class="columns">
            <div class="column" :class="{ 'focus': isOutsideSavingsEdit }" :style="[isOutsideSavingsEdit ? focusStyle : {}]">
              <label class="label toplabel">Enter additional outside savings balances below.</label>
              <b-field label="Taxable Balance">
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your taxable savings balance" v-model.number="taxableBalanceInput" data-vv-name="taxableBalanceInput" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('taxable balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('taxable balance', 'step3') }}</span>
              <b-field label="Tax Deferred Balance">
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your tax deferred savings balance" v-model.number="taxDeferredBalanceInput" data-vv-name="taxDeferredBalanceInput" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('tax deferred balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('tax deferred balance', 'step3') }}</span>
              <b-field label="Tax Exempt Balance">
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your tax exempt savings balance" v-model.number="taxExemptBalanceInput" data-vv-name="tax exempt balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('tax exempt balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('tax exempt balance', 'step3') }}</span>
              <b-field label="Annuity Balance">
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter the balance of all your annuities" v-model.number="annuityBalanceInput" data-vv-name="annuity balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('annuity balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('annuity balance', 'step3') }}</span>
              <b-field label="Inherited IRA Balance">
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter balance of inherited IRAs" v-model.number="inheritedIraBalanceInput" data-vv-name="inherited IRA balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('inherited IRA balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('inherited IRA balance', 'step3') }}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      showOutsideSavings: false,
      planTaxableBalanceInput: null,
      taxableBalanceInput: null,
      taxDeferredBalanceInput: null,
      taxDeferredContributionInput: null,
      planTaxDeferredBalanceInput: null,
      taxExemptBalanceInput: null,
      planTaxExemptBalanceInput: null,
      annuityBalanceInput: null,
      inheritedIraBalanceInput: null,
      options: {
        number: {
          prefix: '$',
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 0
        },
        v_number_required: {
          required: true
        }
      },
      focusStyle: {
        'padding': '10px',
        'border-radius': '4px'
      }
    }
  },
  components: {
  },
  watch: {
    taxableBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxableBalance(newValue)
      }
    },
    taxableBalance: function () {
      this.taxableBalanceInput = this.taxableBalance
    },
    planTaxableBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePlanTaxableBalance(newValue)
      }
    },
    planTaxableBalance: function () {
      this.planTaxableBalanceInput = this.planTaxableBalance
    },
    taxDeferredBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxDeferredBalance(newValue)
      }
    },
    taxDeferredContributionInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxDeferredContribution(newValue)
      }
    },
    taxDeferredContribution: function () {
      this.taxDeferredContributionInput = this.taxDeferredContribution
    },
    planTaxDeferredBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePlanTaxDeferredBalance(newValue)
      }
    },
    planTaxDeferredBalance: function () {
      this.planTaxDeferredBalanceInput = this.planTaxDeferredBalance
    },
    taxExemptBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxExemptBalance(newValue)
      }
    },
    taxExemptBalance: function () {
      this.taxExemptBalanceInput = this.taxExemptBalance
    },
    planTaxExemptBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePlanTaxExemptBalance(newValue)
      }
    },
    planTaxExemptBalance: function () {
      this.planTaxExemptBalanceInput = this.planTaxExemptBalance
    },
    annuityBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateAnnuityBalance(newValue)
      }
    },
    annuityBalance: function () {
      this.annuityBalanceInput = this.annuityBalance
    },
    inheritedIraBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateInheritedIraBalance(newValue)
      }
    },
    inheritedIraBalance: function () {
      this.inheritedIraBalanceInput = this.inheritedIraBalance
    }
  },
  computed: {
    ...mapGetters([
      'taxableBalance',
      'planTaxableBalance',
      'taxDeferredBalance',
      'taxDeferredContribution',
      'planTaxDeferredBalance',
      'taxExemptBalance',
      'planTaxExemptBalance',
      'annuityBalance',
      'inheritedIraBalance',
      'getPlanName',
      'getEditMode'
    ]),
    isOutsideSavingsEdit: function () {
      return (this.getEditMode === 'outsidesavings')
    }
  },
  methods: {
    ...mapActions({
      updateTaxableBalance: 'updateTaxableBalance',
      updatePlanTaxableBalance: 'updatePlanTaxableBalance',
      updateTaxDeferredBalance: 'updateTaxDeferredBalance',
      updatePlanTaxDeferredBalance: 'updatePlanTaxDeferredBalance',
      updateTaxExemptBalance: 'updateTaxExemptBalance',
      updateTaxDeferredContribution: 'updateTaxDeferredContribution',
      updatePlanTaxExemptBalance: 'updatePlanTaxExemptBalance',
      updateAnnuityBalance: 'updateAnnuityBalance',
      updateInheritedIraBalance: 'updateInheritedIraBalance'
    }),
    toggleShowOutsideSavings: function () {
      this.showOutsideSavings = true
    }
  },
  mounted () {
    this.taxableBalanceInput = (this.taxableBalance === 0) ? null : this.taxableBalance
    this.planTaxableBalanceInput = (this.planTaxableBalance === 0) ? null : this.planTaxableBalance
    this.taxDeferredBalanceInput = (this.taxDeferredBalance === 0) ? null : this.taxDeferredBalance
    this.planTaxDeferredBalanceInput = (this.planTaxDeferredBalance === 0) ? null : this.planTaxDeferredBalance
    this.taxExemptBalanceInput = (this.taxExemptBalance === 0) ? null : this.taxExemptBalance
    this.taxDeferredContributionInput = (this.taxDeferredContribution === 0) ? null : this.taxDeferredContribution
    this.planTaxExemptBalanceInput = (this.planTaxExemptBalance === 0) ? null : this.taxExemptBalance
    this.annuityBalanceInput = (this.annuityBalance === 0) ? null : this.annuityBalance
    this.inheritedIraBalanceInput = (this.inheritedIraBalance === 0) ? null : this.inheritedIraBalance
    if (this.taxableBalanceInput > 0 ||
      this.taxDeferredBalanceInput > 0 ||
      this.taxExemptBalanceInput > 0 ||
      this.annuityBalanceInput > 0 ||
      this.inheritedIraBalanceInput > 0) {
      this.showOutsideSavings = true
    }
  },
  created () {
  }
}
</script>

<style scoped>
.additionalsavings-btn{
  border-radius: 30px;
    background: #ffffff;
    border: 2px solid #3a97f7;
    font-weight: 600;
    color: #4e4e4e;
    box-shadow: 2px 3px 3px #8080804a;
    font-size: .85em;
}
</style>
