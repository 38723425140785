const templateMap = {
  qs1: 'QuickstartForm',
  qs2: 'QuickstartForm2',
  qs3: 'QuickstartStepForm',
  qs5: 'QuickstartForm5',
  qs6: 'QuickstartForm6',
  qs7: 'QuickstartForm7',
  qs3mini: 'QuickstartMiniStepForm'
}

export default templateMap
