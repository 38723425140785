<template>
  <div id="savings-result-container">
    <h2 class="subsectionheader">Retirement Resources</h2>
    <div class="columns">
      <div class="column">
        <div class="result-chart-container">
          <retirement-resource-stack width="400" height="500"></retirement-resource-stack>
        </div>
      </div>
      <div class="column">
        <div>
          <div id="savings-result-content" v-html="getContentSnippet('savings-result-content')">
          </div>
        </div>
        <div class="has-text-centered" v-show="getNonPlanTotalAssets == 0">
          <span class="has-text-danger"><font-awesome-icon icon="exclamation-triangle" /> Your current analysis does not include outside savings.</span>
        </div>
        <div>
          <p class="has-text-centered" style="margin-top:10px; padding-top:10px; border-top: 1px solid #e6e6e6;">
          Adjust your spending inputs to see how it will affect your plan.</p>
          Your current cummulative retirement resources are
          <p class="has-text-centered savings-value">{{formattedNumber(getCummulativeRetirementResources)}}</p>
        </div>
        <p class="has-text-centered">
          <button class="button is-secondary savingsadjust" @click="setOutsideSavingsEditMode">Add Additional Savings</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RetirementResourceStack from '@/components/RetirementResourceStack'
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getNonPlanTotalAssets',
      'getPlanTotalAssets',
      'getOptimalSSIAmount',
      'getHouseholdTotalAssets'
    ]),
    getCummulativeRetirementResources () {
      return this.getHouseholdTotalAssets + this.getOptimalSSIAmount
    }
  },
  methods: {
    ...mapActions({
      setEditMode: 'setEditMode'
    }),
    setOutsideSavingsEditMode: function (ev) {
      this.setEditMode('outsidesavings')
      this.$router.push({ path: 'home' })
    },
    formattedNumber: function (val) {
      return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(val)
    }
  },
  components: {
    RetirementResourceStack
  }
}
</script>

<style lang="scss" scoped>
  .columns {
    margin-top: 0px;
  }
  #savings-result-content {
    margin-bottom: 15px;
  }
  .savings-value {
    font-size: 28px;
    padding-bottom: 25px;
  }
  .savingsadjust{
    border-radius: 20px;
    border: 2px solid #3a97f7;
    background-color: #e7f2ff;
    padding: 20px;
    font-weight: 600;
  }
</style>
