<template>
  <transition name="slide-left">
    <div class="home">
      <component :is="useTemplate" :stepColor="wizardStepColor" :errorColor="wizardErrorColor" />
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
import QuickstartForm from '@/components/QuickstartForm'
import QuickstartForm2 from '@/components/QuickstartForm2'
import QuickstartStepForm from '@/components/QuickstartStepForm'
import QuickstartMiniStepForm from '@/components/QuickstartMiniStepForm'
import QuickstartForm5 from '@/components/QuickstartForm5'
import QuickstartForm6 from '@/components/QuickstartForm6'
import QuickstartForm7 from '@/components/QuickstartForm7'
import templateMap from '@/templateMap'

export default {
  name: 'home',
  props: {
    templateKey: '',
    useFullBanner: {
      type: Boolean,
      default: false
    },
    wizardStepColor: '',
    wizardErrorColor: ''
  },
  components: {
    QuickstartForm,
    QuickstartForm2,
    QuickstartStepForm,
    QuickstartForm5,
    QuickstartForm6,
    QuickstartForm7,
    QuickstartMiniStepForm
  },
  data () {
    return {
      templateMap: templateMap
    }
  },
  computed: {
    useTemplate () {
      var templateName = templateMap[this.templateKey]
      return (templateName !== 'undefined') ? templateName : 'QuickstartForm'
    }
  },
  mounted () {
    this.$store.dispatch('setCurrentPage', this.$router.currentRoute.meta.page)
    if (this.$router.currentRoute.meta.useBannerRight) {
      this.$store.dispatch('initializeBannerRight', JSON.parse(this.$router.currentRoute.meta.useBannerRight))
    }
  }
}
</script>
