<template>
  <div id="income-estimates-container">
    <h2 class="sectionheader">Your Estimated Monthly Income</h2>
    <div class="columns">
      <div id="income-estimates-content" class="column" v-html="getContentSnippet('income-estimates-content')">
      </div>
      <div class="column automargins">
        <div v-if="getMoreAmount > -1">
          <b-table :data="incomeTableData" :columns="tableData.columns">
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      tableData: {
        data: [],
        columns: [{
          field: 'age',
          label: 'Retirement Age',
          centered: true,
          numeric: true
        },
        {
          field: 'savingsOnly',
          label: 'Savings',
          centered: true,
          numeric: true
        },
        {
          field: 'savingsWithSs',
          label: 'Savings + Social Security',
          numeric: true,
          centered: true,
          variant: 'success'
        }]
      }
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getMoreAmount',
      'getEstimatedIncomeEntry',
      'clientAge'
    ]),
    incomeTableData () {
      let dataArray = []

      dataArray.push({
        age: 62,
        savingsOnly: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(this.getEstimatedIncomeEntry('earlySavingsOnly')),
        savingsWithSs: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(this.getEstimatedIncomeEntry('earlyWithSocialSecurity'))
      })

      dataArray.push({
        age: (this.clientAge > 60) ? 66 : 67,
        savingsOnly: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(this.getEstimatedIncomeEntry('fraSavingsOnly')),
        savingsWithSs: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(this.getEstimatedIncomeEntry('fraWithSocialSecurity'))
      })

      dataArray.push({
        age: 70,
        savingsOnly: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(this.getEstimatedIncomeEntry('delayedSavingsOnly')),
        savingsWithSs: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(this.getEstimatedIncomeEntry('delayedWithSocialSecurity'))
      })

      return dataArray
    }
  },
  components: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.automargins{
      margin-top: auto;
    margin-bottom: auto;
}
</style>
