<template>
  <section>
    <div class="spendingsection panel" :class="{ 'focus': isSpendingEdit }" :style="[isSpendingEdit ? focusStyle : {}]">
      <p v-show="!hidePanelHeading" class="columnsuperhead">Step 2:</p>
      <p v-if="!hidePanelHeading" class="panel-heading columnhead">Retirement Spending<br/></p>
      <p v-if="!hidePanelHeading" class="text-center"><span class="setamount">Currently set to:</span> <strong class="spendingnum">${{formatSpending(spendingInput)}} /Month</strong></p>
      <div class="panel-block">
        <section id="slider-container">
          <!-- <vueSlider ref="vueslider" v-model='spendingInput' :min="slider.min" :max="slider.max" :interval="slider.interval"
              :lineHeight="slider.lineHeight" :formatter="slider.formatter" :tooltipStyle="slider.tooltipStyle" /> -->
              <b-field label="Monthly Retirement Spending">
              <b-input v-model='spendingInput' placeholder="Example: 6000" step="100" type="number" v-bind:max="slider.max" v-bind:min="slider.min" v-validate="'required|numeric|min_value:' + slider.min +'|max_value:' + slider.max"></b-input>
              </b-field>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueSlideBar from 'vue-slide-bar'
import vueSlider from 'vue-slider-component'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      spendingInput: 0,
      slider: {
        min: 100,
        max: 30000,
        interval: 100,
        lineHeight: 20,
        tooltipStyle: {
          fontSize: '20px'
        },
        formatter: (v) => {
          return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(v) + '/ Month'
        }
      },
      focusStyle: {
        'padding': '10px',
        'border-radius': '4px'
      }
    }
  },
  watch: {
    spendingInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateSpending(newValue)
      }
    },
    spending: function () {
      if (this.spending > 0) {
        this.spendingInput = this.spending
      }
    }
  },
  components: {
    VueSlideBar,
    vueSlider
  },
  computed: {
    ...mapGetters([
      'spending',
      'getEditMode'
    ]),
    isSpendingEdit: function () {
      return (this.getEditMode === 'spending')
    }
  },
  methods: {
    ...mapActions({
      updateSpending: 'updateSpending'
    }),
    refreshSlider () {
      // call refresh on slider to initialize slider size
      // because the initial slider size is 0 when rendered in a hidden tab
      if (this.$refs.vueslider) {
        this.$nextTick(() => this.$refs.vueslider.refresh())
      }
    },
    formatSpending (spending) {
      let val = (spending / 1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  created () {
    if (this.spending > 0) {
      this.spendingInput = this.spending
    } else {
      this.spendingInput = 6000
    }
  },
  mounted () {
    if (this.spending > 0) {
      this.spendingInput = this.spending
    } else {
      this.spendingInput = 6000
    }
    if (this.isSpendingEdit) {
      this.$scrollTo('#spending-container', 300)
    }
  }
}
</script>

<style scoped>
  #slider-container {
    /* padding-top: 50px; */
    width: 100%;
  }
  div.vue-slider{
    height:15px;
  }
  .setamount{
    color: #929292;
    font-size: 16px;
    font-weight: 500;
  }
</style>
