<template>
  <div class="panel" id="income-panel">
    <p v-show="!hidePanelHeading" class="columnsuperhead">Step 4:</p>
    <p v-if="!hidePanelHeading" class="panel-heading columnhead">Retirement Income</p>
    <div class="panel-block">
      <section class="content-section-full">
        <div class="columns">
          <div class="column is-full" :class="{ 'focus': isIncomeEdit }" :style="[isIncomeEdit ? focusStyle : {}]">
            <div id="income-ss">
            <label class="label">Monthly Social Security benefit <b-tooltip type="is-info" label="This amount can be obtained from your Social Security Statement. Register at www.SSA.gov/myaccount to view your Social Security Statement. Enter your full retirement age benefit here." position="is-left" multilined><i class="fas fa-info-circle moreinfo"></i></b-tooltip></label>
            <b-field>
              <cleave class="input control" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter Social Security amount at Full Retirement Age" v-model.number="clientSocialSecurityBenefitAmountInput" data-vv-name="social security benefit amount" data-vv-scope="step4" v-validate="options.v_number_required" :options="options.number"></cleave>
            </b-field>
            <span v-show="errors.has('social security benefit amount', 'step4')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('social security benefit amount', 'step4') }}</span>
            <div v-show="isMarried">
              <b-field label="Partner's monthly Social Security benefit?">
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter Social Security amount at Full Retirement Age" v-model.number="spouseSocialSecurityBenefitAmountInput" data-vv-name="spouse social security benefit amount" data-vv-scope="step4" v-validate="{ rules: { required: spouseRequired } }" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('spouse social security benefit amount', 'step4')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('spouse social security benefit amount', 'step4') }}</span>
            </div>
            </div>
            <div id="income-pension">
            <label class="label">Monthly retirement pension (if applicable)</label>
            <b-field>
              <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="$0" v-model.number="clientMonthlyPensionInput" data-vv-name="monthly pension" data-vv-scope="step4" v-validate="'min_value:0|numeric'" :options="options.number"></cleave>
            </b-field>
            <span v-show="errors.has('monthly pension', 'step4')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('monthly pension', 'step4') }}</span>
            </div>
            <label class="label">Any additional monthly retirement income <b-tooltip type="is-info" label="Include monthly annuity payments if applicable, rental income, or any income expected to continue indefinitely." position="is-left" multilined><i class="fas fa-info-circle moreinfo"></i></b-tooltip></label>
            <b-field>
              <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="$0" v-model.number="clientOtherMonthlyIncomeInput" data-vv-name="other income" data-vv-scope="step4" v-validate="'min_value:0|numeric'" :options="options.number"></cleave>
            </b-field>
            <span v-show="errors.has('other income', 'step4')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('other income', 'step4') }}</span>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- / div END -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      clientSocialSecurityBenefitAmountInput: null,
      spouseSocialSecurityBenefitAmountInput: null,
      clientOtherMonthlyIncomeInput: null,
      clientMonthlyPensionInput: null,
      options: {
        number: {
          prefix: '$',
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 0
        },
        v_number_required: {
          required: true
        }
      },
      focusStyle: {
        'padding': '10px',
        'border-radius': '4px'
      }
    }
  },
  watch: {
    clientSocialSecurityBenefitAmountInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientSocialSecurityBenefitAmount(newValue)
      }
    },
    spouseSocialSecurityBenefitAmountInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePartnerSocialSecurityBenefitAmount(newValue)
      }
    },
    clientMonthlyPensionInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientMonthlyPension(newValue)
      }
    },
    clientOtherMonthlyIncomeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientOtherMonthlyIncome(newValue)
      }
    }
  },
  computed: {
    ...mapGetters([
      'clientSocialSecurityBenefitAmount',
      'partnerSocialSecurityBenefitAmount',
      'clientMonthlyPension',
      'clientOtherMonthlyIncome',
      'isMarried',
      'getEditMode'
    ]),
    spouseRequired: function () {
      if (this.married === 'true') {
        return true
      } else {
        return false
      }
    },
    isIncomeEdit: function () {
      return (this.getEditMode === 'income')
    }
  },
  methods: {
    ...mapActions({
      updateClientSocialSecurityBenefitAmount: 'updateClientSocialSecurityBenefitAmount',
      updatePartnerSocialSecurityBenefitAmount: 'updatePartnerSocialSecurityBenefitAmount',
      updateClientMonthlyPension: 'updateClientMonthlyPension',
      updateClientOtherMonthlyIncome: 'updateClientOtherMonthlyIncome'
    })
  },
  mounted () {
    this.clientSocialSecurityBenefitAmountInput = (this.clientSocialSecurityBenefitAmount === 0) ? null : this.clientSocialSecurityBenefitAmount
    this.spouseSocialSecurityBenefitAmountInput = (this.partnerSocialSecurityBenefitAmount === 0) ? null : this.partnerSocialSecurityBenefitAmount
    this.clientMonthlyPensionInput = (this.clientMonthlyPension === 0) ? null : this.clientMonthlyPension
    this.clientOtherMonthlyIncomeInput = (this.clientOtherMonthlyIncome === 0) ? null : this.clientOtherMonthlyIncome
  },
  created () {
  }
}
</script>

<style scoped>
#income-ss{
  margin-bottom:25px;
}
#income-pension{
  margin-bottom:25px;
}
</style>
