<template>
  <div>
    <div class="retirement-resources-stack" :class="customClass">
      <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height">
        <svg :y="planSavingsStackStart">
          <text x="50" :dy="planSavingsStackHeight / 2" width="100">
            <tspan>
              Employer
            </tspan>
            <tspan x="50" dy="25">
              Plan Savings
            </tspan>
          </text>
          <rect id="plan-block" x="200" :width="stackWidth" :height="planSavingsStackHeight" :fill="planSavingsStackFillColor"></rect>
          <text x="300" :dy="planSavingsStackHeight / 2">{{formattedNumber(getPlanTotalAssets)}}</text>
        </svg>
        <svg :y="socialSecurityStackStart">
          <text x="50" :dy="socialSecurityStackHeight / 2" width="100">
            <tspan>
              Cummulative
            </tspan>
            <tspan x="50" dy="25">
              Social Security*
            </tspan>
            </text>
          <rect id="ss-block" x="200" :width="stackWidth" :height="socialSecurityStackHeight" :fill="socialSecurityStackFillColor" ></rect>
          <text x="300" :dy="socialSecurityStackHeight / 2">{{formattedNumber(getOptimalSSIAmount)}}</text>
        </svg>
        <svg :y="outsideSavingsStackStart">
          <text x="50" :dy="outsideSavingsStackHeight / 2" width="100">Outside Savings</text>
          <rect id="savings-block" x="200" :width="stackWidth" :height="outsideSavingsStackHeight" :fill="outsideSavingsStackFillColor"></rect>
          <text x="300" :dy="outsideSavingsStackHeight / 2">{{formattedNumber(getNonPlanTotalAssets)}}</text>
          <text x="50" :dy="25 + outsideSavingsStackHeight" style="font-size: 0.5em">* Estimated total Social Security Benefits received over your lifetime implementing an optimal claiming strategy</text>
        </svg>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    options: {
      type: Object,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    customClass: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      planSavingsStackStart: 0,
      planSavingsStackHeight: 100,
      socialSecurityStackStart: 100,
      socialSecurityStackHeight: 100,
      outsideSavingsStackStart: 100,
      outsideSavingsStackHeight: 100
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getOptimalSSIAmount',
      'getPlanTotalAssets',
      'getNonPlanTotalAssets',
      'getHouseholdTotalAssets'
    ]),
    height () {
      return this.defaultOptions.layout.height
    },
    width () {
      return this.defaultOptions.layout.width
    },
    stackWidth () {
      return 80
    },
    planSavingsStackFillColor () {
      return this.defaultOptions.fillColor.planSavings
    },
    socialSecurityStackFillColor () {
      return this.defaultOptions.fillColor.socialSecurity
    },
    outsideSavingsStackFillColor () {
      return (this.getNonPlanTotalAssets === 0) ? this.defaultOptions.fillColor.emptySavings : this.defaultOptions.fillColor.outsideSavings
    }
  },
  methods: {
    mergeDefaultOptionsWithProp: function (options) {
      var result = this.defaultOptions
      for (var option in options) {
        if (options[option] !== null && typeof (options[option]) === 'object') {
          for (var subOption in options[option]) {
            if (options[option][subOption] !== undefined && options[option][subOption] !== null) {
              result[option][subOption] = options[option][subOption]
            }
          }
        } else {
          result[option] = options[option]
        }
      }
    },
    formattedNumber: function (val) {
      return (val === 0) ? '???' : '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(val)
    },
    calculateStacks: function () {
      this.planSavingsStackStart = this.defaultOptions.layout.top
      let totalResources = 0
      if (this.getNonPlanTotalAssets > 0) {
        totalResources = this.getHouseholdTotalAssets + this.getOptimalSSIAmount
        let outsidePercentage = this.getNonPlanTotalAssets / totalResources
        this.outsideSavingsStackHeight = ((300 * outsidePercentage) < 30) ? 30 : 300 * outsidePercentage
      } else {
        totalResources = this.getHouseholdTotalAssets + this.getOptimalSSIAmount
        this.outsideSavingsStackHeight = 100
      }
      let planPercentage = this.getPlanTotalAssets / totalResources
      this.planSavingsStackHeight = ((300 * planPercentage) < 30) ? 30 : 300 * planPercentage
      this.socialSecurityStackStart = this.planSavingsStackHeight + this.planSavingsStackStart + 10
      this.socialSecurityStackHeight = 300 - (this.planSavingsStackHeight + this.outsideSavingsStackHeight)
      this.outsideSavingsStackStart = this.socialSecurityStackHeight + this.socialSecurityStackStart + 10
    }
  },
  created () {
    this.defaultOptions = {
      text: {
        color: 'black',
        fontSize: 10,
        textAdjustmentY: 2,
        fontFamily: 'Arial',
        textEnabled: true
      },
      fillColor: {
        planSavings: '#0191c8',
        socialSecurity: '#0fa60e',
        outsideSavings: '#74c2e1',
        emptySavings: '#8c8984'
      },
      layout: {
        height: 375,
        width: 550,
        top: 20
      }
    }
  },
  mounted () {
    if (this.options !== null && this.options !== undefined) {
      this.mergeDefaultOptionsWithProp(this.options)
    }
    this.calculateStacks()
  }
}
</script>

<style lang="scss" scoped>
  .retirement-resources-stack {
    font-size: 20px;
  }
</style>
