<template>
  <div id="email-form">
    <form ref="form" @submit.prevent="onSubmit">
      <div class="panel">
        <p class="emailheading">Download Quickstart Report</p>
        <div class="panel-block">
          <section class="content-section-full">
            <label class="emaillabel">Client Name</label>
            <b-field>
              <b-input v-model="retireeNameInput" ></b-input>
            </b-field>
          </section>
        </div>
        <div class="panel-block">
          <button :disabled="errors.any()" class="button is-primary">Download Retirement Plan Report</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import VueTelInput from 'vue-tel-input'

const monthlyApiService = new MonthlyApiService()

export default {
  inject: ['$validator'],
  data () {
    return {
      retireeNameInput: null
    }
  },
  components: {
    VueTelInput
  },
  computed: {
    ...mapGetters([
      'clientName'
    ])
  },
  watch: {
  },
  methods: {
    ...mapActions({
      setDownloadReportModalActive: 'setDownloadReportModalActive',
      setPleaseWaitModalActive: 'setPleaseWaitModalActive'
    }),
    onSubmit: function () {
      this.submitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          this.setDownloadReportModalActive(false)
          this.setPleaseWaitModalActive(true)
          monthlyApiService.downloadReport(this.retireeNameInput).then((response) => {
            this.setPleaseWaitModalActive(false)
          }
          ).catch((error) => {
            alert(error)
          }
          ).finally(() => {
            this.setPleaseWaitModalActive(false)
          })
        }
      })
    }
  },
  mounted () {
    this.retireeNameInput = this.clientName
  },
  created () {
    this.retireeNameInput = this.clientName
  }
}
</script>

<style scoped>
  #email-form .label {
    color: #ffffff;
  }
  .emaillabel{
    color:#fff;
    font-size:22px;
    font-weight:600;
  }
  .emailheading{
    color:#fff;
    font-size:32px;
    font-weight:600;
  }
  .vue-tel-input{
    background-color: white;
    border-radius: 32px !important;
    height: 47px;
    border: 1px solid #dbdbdb !important;
  }
  .vue-tel-input > input[type="tel"] {
    border-radius: 30px !important;
}

</style>
