<template>
  <div class="container" v-if="typeof(quickstartRequest.requestId) !== 'undefined' && quickstartRequest.requestId !== null">
    <form ref="form" @submit.prevent="onSubmit">
      <div class="inputshome">
        <div class="container columns is-desktop" style="margin-bottom: 0px;">
          <div class="column is-full-desktop">
            </div>
        </div>
        <div id="input-container" class="container columns is-desktop">
            <div class="column is-one-third-desktop inputcol" >
              <b-collapse
                class="card"
                animation="fade"
                v-for="(collapse, index) of collapses"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index">
                <template #trigger="props">
                    <div
                      class="card-header"
                      role="button"
                    >
                        <p class="card-header-title">
                            {{ collapse.title }}
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? ' fas fa-chevron-up' : ' fas fa-chevron-down'"></b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content" :id="collapse.collapseId">
                    <div class="content">
                       <component v-bind:is="collapse.componentName" v-bind:v-show="collapse.showAttr"></component>
                       <component v-bind:is="collapse.componentName2" v-bind:v-show="collapse.showAttr2"></component>
                       <div class="componentfooter">
                          <b-button v-if="collapse.btn2title" type="is-primary" @click="backCollapse" class="backBtn"> {{collapse.btn2title}} </b-button>
                          <b-button v-if="collapse.btn1title" type="is-success" @click="nextCollapse" class="nextBtn"> {{collapse.btn1title}} </b-button>
                       </div>
                    </div>
                </div>
            </b-collapse>
              <div class="text-center">
                <button :disabled="errors.any()" class="button is-success calcBtn btn-block" style="margin-top:10px;" >
                  Calculate Strategy
                </button>
              </div>
            </div>
            <div class="column is-two-thirds-desktop resultcol">
              <div id="top-container" class="container columns is-desktop">
                <div v-if="submitted">
                   <results-accordion/>
                </div>
                <div id="promo-container" class="column" v-else>
                  <div v-html="getContentSnippet('promo-content')">
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </form>
    <b-modal id="help-video" :active="youtubeModalActive" scroll="keep" :onCancel="() => this.youtubeModalActive = false">
      <div v-html="getContentSnippet('input-tutorial-1')">
      </div>
    </b-modal>
  </div>
</template>

<script>
import Demographics from '@/components/Demographics'
import Savings from '@/components/Savings'
import SavingsPlan from '@/components/SavingsPlan'
import Income from '@/components/Income'
import Spending from '@/components/SpendingSlider'
import SpendingInput from '@/components/SpendingInput'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import { mapGetters, mapActions } from 'vuex'
import { Tweet } from 'vue-tweet-embed'
import ResultsAccordion from '../views/ResultsAccordion.vue'

const monthlyApiService = new MonthlyApiService()

export default {
  name: 'quickstartForm7',
  components: {
    Demographics,
    Savings,
    SavingsPlan,
    Income,
    Spending,
    Tweet,
    SpendingInput,
    ResultsAccordion
  },
  data () {
    return {
      Demographicssubmitted: false,
      helpSnackbarVisible: false,
      youtubeModalActive: false,
      submitted: false,
      videoId: 'NpEaa2P7qZI',
      isOpen: 0,
      collapses: [
        {
          title: 'Basic Info',
          collapseId: 'demopanel',
          text: 'savings',
          componentName: 'Demographics',
          showAttr: 'getPlanType === null',
          btn1title: 'Next'
        },
        {
          title: 'Retirement Spending',
          text: 'Text 2',
          componentName: 'spending-input',
          btn1Method: 'nextCollapse',
          btn1title: 'Next',
          btn2title: 'Back'

        },
        {
          title: 'Retirement Savings',
          text: 'savings',
          componentName: 'savings-plan',
          showAttr2: 'getPlanType !== null',
          btn1title: 'Next',
          btn2title: 'Back'
        },
        {
          title: 'Retirement Income',
          text: 'Text 3',
          componentName: 'income',
          btn2title: 'Back',
          btn3title: 'Submit'
        }
      ]
    }
  },
  watch: {
    helpSnackbarVisible: function (val) {
      if (val) {
        this.showHelpSnackbar()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getPlanType',
      'getUseGetMoreTeaser',
      'quickstartRequest'
    ])
  },
  methods: {
    ...mapActions({
      moveStep: 'moveStep',
      setGetMoreTeaserModalActive: 'setGetMoreTeaserModalActive'
    }),
    onSubmit () {
      this.moveStep('1')
      this.$tours['inputTour'].stop()
      monthlyApiService.startQuickstart().then((response) => {
        this.$store.dispatch('updateQuickstartRequestFromResponse', response.data.payload)
        monthlyApiService.startOptimize().then((response) => {
          this.$store.dispatch('updateQuickstartResults', response.data.payload)
          if (this.$store.getters.getUseGetMoreTeaser) {
            this.setGetMoreTeaserModalActive(true)
          } else {
            // this.$router.push({ path: 'results' })
            this.submitted = true
          }
        }
        )
      }
      )
    },
    showHelpSnackbar () {
      if (this.getPlanType === '401k' && !this.youtubeModalActive) {
        if (!this.$cookies.isKey('show_snackbar_count') ||
          parseInt(this.$cookies.get('show_snackbar_count')) <= 3) {
          this.$snackbar.open({
            message: 'Would you like to see a brief tutorial?',
            type: 'is-success',
            position: 'is-top',
            actionText: 'Yes',
            indefinite: false,
            duration: 30000,
            onAction: () => {
              this.youtubeModalActive = true
            }
          })
          let snackbarCount = (!this.$cookies.isKey('show_snackbar_count')) ? 1 : 1 + parseInt(this.$cookies.get('show_snackbar_count'))
          this.$cookies.set('show_snackbar_count', snackbarCount.toString())
        }
      }
    },
    playVideo () {
      this.$refs.player.player.playVideo()
    },
    pauseVideo () {
    },
    nextCollapse () {
      this.isOpen++
    },
    backCollapse () {
      this.isOpen--
    }
  },
  mounted () {
    this.$store.dispatch('clearDirtyForm')
  },
  onIdle () {
    this.helpSnackbarVisible = true
  },
  onActive () {
    this.helpSnackbarVisible = false
  }
}
</script>

<style lang="scss" scoped>
.columnhead{
  display:none !important;
}
.columnsuperhead{
  display:none !important;
}
$snackbar-background-color: #ffffff;

#top-container, #input-container {
  margin: 0 10px 10px 10px;
}
#input-container, #spending-container {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: 1px solid black;
}
#spending-container {
  margin:0 5px 0 0;
}
#promo-container {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  margin-left: 5px;
}
#calculate-button-container {
  padding: 0px 15px;
  margin-top:25px;
  text-align: right;
}

.banner-image > div > img {
    height: 350px;
}
#help-video .modal-content {
  overflow: hidden;
}

@media (min-width: 769px) {
  .has-right-border {
    border-right: 1px solid #dbdbdb;
  }
}

@media (max-width: 768px) {
  .has-right-border {
    border-bottom: 1px solid #dbdbdb;
  }
}
.resultspage{
  width:800px !important;
}
div.hero-head{
  display: none !important;
}
</style>
