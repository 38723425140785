<template>
  <div class="columns bottom-section" v-if="typeof(quickstartRequest.requestId) !== 'undefined' && quickstartRequest.requestId !== null">
    <div id="form-container" class="column">
      <form ref="form" @submit.prevent="onSubmit">
        <form-wizard @on-complete="onSubmit" title="" subtitle="" shape="circle" @on-change="refreshSlider" transition="fade" :color="stepColor" :errorColor="errorColor">
          <tab-content v-if="getUseEmailStarter" title="Get Started" icon="fas fa-user" :before-change="saveContact">
            <email-starter hide-panel-heading></email-starter>
          </tab-content>
          <tab-content title="Household Profile" icon="fas fa-home" :before-change="() => stepComplete('Profile', 'step1')">
            <demographics hide-panel-heading></demographics>
          </tab-content>
          <tab-content title="Monthly Spending" icon="fas fa-shopping-cart">
            <spending hide-panel-heading ref="spending" />
          </tab-content>
          <tab-content title="Retirement Savings" icon="fas fa-money-bill-wave" :before-change="() => stepComplete('Savings', 'step3')">
            <savings v-if="getPlanType === null || getPlanType === 'None'" hide-panel-heading></savings>
            <savings-plan v-else hide-panel-heading></savings-plan>
          </tab-content>
          <tab-content title="Retirement Income" icon="fas fa-money-check-alt" :before-change="() => stepComplete('Income', 'step4')">
            <income hide-panel-heading ></income>
          </tab-content>
        </form-wizard>
      </form>
    </div>
    <div class="column">
      <div id="promo-container" class="column">
        <div v-html="getContentSnippet('promo-content')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters, mapActions } from 'vuex'
import EmailStarter from '@/components/EmailStarter'
import Demographics from '@/components/Demographics'
import Savings from '@/components/Savings'
import SavingsPlan from '@/components/SavingsPlan'
import Income from '@/components/Income'
import Spending from '@/components/SpendingSlider'
import { MonthlyApiService } from '@/services/MonthlyApiService'

const monthlyApiService = new MonthlyApiService()

export default {
  inject: ['$validator'],
  provide () {
    return {
      $validator: this.$validator
    }
  },
  props: {
    stepColor: {
      type: String,
      default: null
    },
    errorColor: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showProcessing: false,
      showResults: false,
      profileComplete: false,
      spendingComplete: false,
      savingsComplete: false,
      incomeComplete: false
    }
  },
  components: {
    EmailStarter,
    FormWizard,
    TabContent,
    Demographics,
    Income,
    Savings,
    SavingsPlan,
    Spending
  },
  computed: {
    ...mapGetters({
      stepper: 'stepper',
      getContentSnippet: 'getContentSnippet',
      getPlanType: 'getPlanType',
      getUseEmailStarter: 'getUseEmailStarter',
      quickstartRequest: 'quickstartRequest',
      resultsPage: 'resultsPage',
      resultsAnchor: 'resultsAnchor'
    })
  },
  methods: {
    ...mapActions({
      moveStep: 'moveStep',
      setGetMoreTeaserModalActive: 'setGetMoreTeaserModalActive'
    }),
    saveContact () {
      return new Promise((resolve, reject) => {
        this.$validator.validate('step0.*').then(valid => {
          if (valid) {
            if (this.$store.getters.requestHash === 'new') {
              monthlyApiService.startQuickstart().then((response) => {
                this.$store.dispatch('updateQuickstartRequestFromResponse', response.data.payload)
              }
              )
            }
          }
          resolve(valid)
        }
        )
        this.$gtm.trackEvent({
          event: 'quickstart_step_event',
          category: 'Quickstart Steps',
          action: 'complete',
          label: 'Get Started'
        })
      }
      )
    },
    onSubmit () {
      monthlyApiService.startQuickstart().then((response) => {
        this.$store.dispatch('updateQuickstartRequestFromResponse', response.data.payload)
        monthlyApiService.startOptimize().then((response) => {
          this.$store.dispatch('updateQuickstartResults', response.data.payload)
          if (this.$store.getters.getUseGetMoreTeaser) {
            this.setGetMoreTeaserModalActive(true)
          } else {
            if (this.resultsAnchor) {
              this.$router.push({ path: this.resultsPage, hash: '#' + this.resultsAnchor })
            } else {
              this.$router.push({ path: this.resultsPage })
            }
          }
        }
        )
      }
      )
    },
    refreshSlider: function () {
      if (this.$refs.spending) {
        this.$refs.spending.refreshSlider()
      }
    },
    resetValidator: function () {
      this.$nextTick(() => {
        this.errors.clear()
        this.$nextTick(() => {
          this.$validator.reset()
        })
      })
    },
    tabChange: function () {
      let activeTab = this.$refs.wizard.tabs[this.$refs.wizard.activeTabIndex].title
      this.$gtm.trackEvent({
        event: 'quickstart_step_event',
        category: 'Quickstart Steps',
        action: 'start',
        label: activeTab
      })
      if (this.$refs.spending) {
        this.$refs.spending.refreshSlider()
      }
    },
    stepComplete: function (step, stepScope) {
      return new Promise((resolve, reject) => {
        this.$validator.validate(stepScope + '.*').then(valid => {
          if (valid) {
            this.$gtm.trackEvent({
              event: 'quickstart_step_event',
              category: 'Quickstart Steps',
              action: 'complete',
              label: step
            })
          }
          resolve(valid)
        }
        )
      }
      )
    }
  },
  mounted () {
    this.errors.clear()
    this.$validator.reset()
  }
}
</script>

<style scoped>
#form-container {
  flex-grow: 2;
  padding: 20px;
  border: 2px solid;
  border-radius: 20px;
  background-color: #ffffff;
}

#widget-container .navbar {
  min-height: auto;
}
</style>
