<template>
  <div v-if="getSubOptimalSSIResults" id="ss-get-more-container">
    <h2 class="sectionheader">Social Security</h2>
    <div id="ss-get-more-lead-content" v-html="getContentSnippet('ss-get-more-lead-content')">
    </div>
    <div class="columns is-desktop">
      <div class="column">
        <div id="ss-get-more-chart-container">
          <s-s-benefits-bar-chart :chart-data="getSSBenefitsChartData" :options="getSSBenefitsChartOptions" :width="300" :height="300"></s-s-benefits-bar-chart>
        </div>
        <div id="ss-get-more-content" v-html="getContentSnippet('ss-get-more-content')">
        </div>
      </div>
      <div class="column">
        <div id="ss-break-even-chart-container">
          <s-s-break-even-line-chart :chart-data="getSSBreakEvenChartData" :options="getSSBreakEvenChartOptions" :width="300" :height="300"></s-s-break-even-line-chart>
        </div>
        <div id="ss-break-even-content" v-html="getContentSnippet('ss-break-even-content')">
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="thickdivider"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SSBenefitsBarChart from '@/components/charts/SSBenefitsBarChart.js'
import SSBreakEvenLineChart from '@/components/charts/SSBreakEvenLineChart.js'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

class LineChartItem {
  constructor (label, data, borderColor) {
    this.label = label
    this.data = data
    this.borderColor = borderColor
  }
}

export default {
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltipenabled: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 8,
            fontSize: 14
          }
        },
        pointLabels: {
          display: false
        },
        scales: {
          yAxes: [{
            id: 'default',
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                if (label) {
                  return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(label)
                }
              }
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 0,
            font: {
              size: 14
            },
            display: function (context) {
              var currentDataset = context.dataset
              return (currentDataset.waterfall) ? !currentDataset.waterfall.dummyStack : true
            },
            formatter: function (value, context) {
              if (value) {
                return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value)
              }
            }
          }
        }
      },
      lineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 8,
            fontSize: 14
          }
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          yAxes: [{
            id: 'default',
            ticks: {
              callback: function (label, index, labels) {
                if (label) {
                  return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(label)
                }
              }
            }
          }]
        },
        plugins: {
          datalabels: {
            display: false
          }
        }
      }
    }
  },
  components: {
    SSBenefitsBarChart,
    SSBreakEvenLineChart,
    ChartJsPluginDataLabels
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getOptimalSSIResults',
      'getSubOptimalSSIResults',
      'getMoreAmount',
      'getSubOptimalSSIAmount',
      'getOptimalSSIAmount'
    ]),
    getSSBenefitsChartData: function () {
      let chartData = {}
      let datasets = []
      datasets.push({ data: [this.getSubOptimalSSIAmount], backgroundColor: '#343438', label: 'Typical Strategy' })
      datasets.push({ data: [this.getOptimalSSIAmount], backgroundColor: '#2a97f7', label: 'Smart Strategy' })
      chartData.datasets = datasets
      return chartData
    },
    getSSBenefitsChartOptions: function () {
      let opts = this.options
      // bump up the max from the tallest bar to provide room so amounts can be displayed above the bars
      opts.scales.yAxes[0].ticks.suggestedMax = this.getOptimalSSIAmount * 1.2
      return opts
    },
    getSSBreakEvenChartData: function () {
      let chartData = {}
      let datasets = []
      let labels = []

      let subOptimalDataset = new LineChartItem('Typical Strategy', [], ['#343438'])

      this.getSubOptimalSSIResults.forEach(result => {
        let label = ['Age:' + result.ca.y + ' [' + result.y + ']']
        labels.push(label)
        subOptimalDataset.data.push(result.tacb)
      })

      let optimalDataset = new LineChartItem('Smart Strategy', [], ['#2a97f7'])
      this.getOptimalSSIResults.forEach(result => {
        optimalDataset.data.push(result.tacb)
      })

      datasets.push(subOptimalDataset)
      datasets.push(optimalDataset)

      chartData.labels = labels
      chartData.datasets = datasets
      return chartData
    },
    getSSBreakEvenChartOptions: function () {
      let opts = this.lineOptions
      // bump up the max from the tallest bar to provide room so amounts can be displayed above the bars
      // opts.scales.yAxes[0].ticks.suggestedMax = this.getOptimalAmount * 1.2
      return opts
    }
  }
}
</script>

<style scoped>
  #ss-get-more-chart-container,
  #ss-break-even-chart-container {
    background-color: #ffffff;
    padding: 15px 10px;
    border-radius:25px;
  }
  #ss-get-more-lead-content {
    padding-bottom: 10px;
  }
</style>
