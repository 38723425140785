<template>
  <div class="container resultspage">
    <back-to-top visibleoffset="500">
      <!-- <button type="button" class="btn btn-info btn-back-to-top"><font-awesome-icon size="3x" :icon="[ 'far', 'arrow-alt-circle-up']"/></button> -->
       <button type="button" class="btn btn-info btn-back-to-top"><i class="fas fa-arrow-up"></i></button>
    </back-to-top>
    <floating-button v-show="getMoreAmount > 0" top="750px" visibleoffset="500">
      <button v-if="isInternalTool" type="button" class="button is-primary btn-email-report" @click="setDownloadReportModalActive(true)">Download Report</button>
      <button v-else type="button" class="button is-primary btn-email-report" @click="setEmailReportModalActive(true)">Get Your Free Report</button>
    </floating-button>
    <div class="columns">
      <div class="column">
        <get-more></get-more>
      </div>
    </div>
    <!-- <div class="is-divider"></div> -->
    <div id="indicators-container" class="columns has-text-centered" style="display: none;">
      <div class="column">
        <total-value-indicator></total-value-indicator>
      </div>
      <div class="column">
        <longevity-indicator></longevity-indicator>
      </div>
      <div class="column">
        <ending-value-indicator></ending-value-indicator>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <get-more-zone v-show="getMoreAmount > -1"></get-more-zone>
        </transition>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <s-s-get-more></s-s-get-more>
        </transition>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <sensitivity-impacts v-show="getMoreAmount > -1"></sensitivity-impacts>
        </transition>
      </div>
    </div>
    <div class="columns" v-show="getMoreAmount > -1">
      <div class="column">
        <transition name="slide-fade">
          <longevity  v-if="getLongevityIndicatorValue > 3 || getEndingValueIndicatorAmount >= 10000"></longevity>
          <investment-growth v-else></investment-growth>
        </transition>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <income-estimates v-show="getMoreAmount > -1"></income-estimates>
        </transition>
      </div>
    </div>
    <div class="evenMoreSection" v-show="getMoreAmount > -1 && (getPlanType === '401k' || getPlanType === 'Roth')">
       <h2 class="sectionheader">You Can Get Even More!</h2>
       <p>There is room for improvement that could pay off big when you make a few adjustments.</p>
       <div class="sectionhighlight">
        <div class="columns">
          <div class="column">
            <transition name="slide-fade">
              <spending-result v-if="getMoreAmount > -1"></spending-result>
            </transition>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <transition name="slide-fade">
              <!-- use v-if so component is rendered after results are returned -->
              <savings-result v-if="getMoreAmount > -1 && getOptimalSSIAmount > 0"></savings-result>
            </transition>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <transition name="slide-fade">
              <other-income-result v-show="getMoreAmount > -1"></other-income-result>
            </transition>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <transition name="slide-fade">
              <investment-risk-result v-show="getMoreAmount > -1"></investment-risk-result>
            </transition>
          </div>
        </div>
       </div>
    </div>
    <div class="columns" v-if="showAllocationSection">
        <div class="column">
          <allocation></allocation>
      </div>
    </div>

    <div class="columns">
        <div v-show="getMoreAmount > -1" v-html="getContentSnippet('results-cta')" class="results-cta">
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import GetMore from '@/components/GetMore'
import Longevity from '@/components/Longevity'
import InvestmentGrowth from '@/components/InvestmentGrowth'
import IncomeEstimates from '@/components/IncomeEstimates'
import GetMoreZone from '@/components/GetMoreZone'
import SSGetMore from '@/components/SSGetMore'
import TotalValueIndicator from '@/components/TotalValueIndicator'
import LongevityIndicator from '@/components/LongevityIndicator'
import EndingValueIndicator from '@/components/EndingValueIndicator'
import SensitivityImpacts from '@/components/SensitivityImpacts'
import SpendingResult from '@/components/SpendingResult'
import SavingsResult from '@/components/SavingsResult'
import OtherIncomeResult from '@/components/OtherIncomeResult'
import InvestmentRiskResult from '@/components/InvestmentRiskResult'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import BackToTop from 'vue-backtotop'
import FloatingButton from '@/components/FloatingButton'
import Allocation from '@/components/Allocation'

const monthlyApiService = new MonthlyApiService()

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'getLongevityIndicatorValue',
      'getMoreAmount',
      'getOptimalSSIAmount',
      'getEndingValueIndicatorAmount',
      'getPlanType',
      'getContentSnippet',
      'quickstartResults',
      'isInternalTool',
      'requestHash',
      'betterGetMoreFound'
    ]),
    ...mapState([
      'quickstartConfiguration'
    ]),
    reportUrl: function () {
      return process.env.VUE_APP_QS_URL + '/public/quickstart/report.pdf?key=' + this.requestHash
    },
    showAllocationSection: function () {
      return this.quickstartConfiguration.annuityPercent > 0
    }
  },
  watch: {
    betterGetMoreFound: function () {
      if (this.betterGetMoreFound) {
        this.$snackbar.open({
          message: 'Congratulations! We found even more using our advanced calculations.  Would you like to update your results?',
          type: 'is-success',
          position: 'is-top',
          actionText: 'Yes',
          indefinite: false,
          duration: 15000,
          onAction: () => {
            this.updateWithBestResults()
          }
        })
      }
      return null
    }
  },
  components: {
    GetMore,
    SSGetMore,
    Longevity,
    InvestmentGrowth,
    IncomeEstimates,
    GetMoreZone,
    TotalValueIndicator,
    LongevityIndicator,
    EndingValueIndicator,
    SensitivityImpacts,
    SpendingResult,
    SavingsResult,
    OtherIncomeResult,
    InvestmentRiskResult,
    BackToTop,
    FloatingButton,
    Allocation
  },
  methods: {
    ...mapActions({
      setEmailReportModalActive: 'setEmailReportModalActive',
      setDownloadReportModalActive: 'setDownloadReportModalActive',
      setPleaseWaitModalActive: 'setPleaseWaitModalActive',
      updateWithBestResults: 'updateWithBestResults'
    })
  },
  async created () {
    if (Object.keys(this.quickstartResults).length === 0) {
      await monthlyApiService.startQuickOptimize().then((response) => {
        this.$store.dispatch('updateQuickstartResults', response.data.payload)
      })
    }
    monthlyApiService.getQuickResults().then(() => {
      monthlyApiService.getQuickSensitivityResults().then(() => {
      })
    })
  },
  mounted () {
    this.$store.dispatch('setCurrentPage', this.$router.currentRoute.meta.page)
    this.$store.dispatch('clearEditMode')
  }
}
</script>

<style scoped>
  #indicators-container {
    display: none;
  }
  #resultsbg{
    background:#fff;
    width:100%;
  }
  #widget-container .btn-back-to-top {
    background-color: #209cee;
    border-radius: 30px;
    color: #ffffff;
    height: 60px;
    text-align: center;
    width: 60px;
    padding: 0px;
    border-style: solid;
  }
  .fa-arrow-up{
    font-size:26px;
  }
   #widget-container .btn-email-report {
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 1.5rem;
    text-align: center;
    white-space: nowrap;
  }
  .btn-email-report{
    font-weight: 700;
  }

/*   .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  } */
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .sectionhighlight{
    border: 6px solid #3a97f7;
    padding: 20px;
    border-radius: 20px;
    margin-top: 15px;
  }
  .evenMoreSection{
    margin-bottom:30px;
  }
</style>
