<template>
  <div v-if="isCosmicLoadComplete" id="banner-container">
      <h1 class="bigcta"  v-html="getContentSnippet('banner-cta')"></h1>
      <div :class="(useBannerRight) ? 'bannertext' : 'bannertextfull'">
          <div v-html="getContentSnippet('banner-content')" class="banner-content">
          </div>
      </div>
      <div v-if="useBannerRight" v-html="getContentSnippet('banner-right')" class="banner-right">
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'isCosmicLoadComplete',
      'getContentSnippet',
      'getImageUrl',
      'useBannerRight'
    ])
  }
}
</script>

<style scoped>
#banner-container {
  margin-top: 40px;
  display:grid;
  grid-gap:10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.bigcta{
  font-size:3.2em;
  line-height:1em;
  font-weight:800;
  padding-bottom:20px;
  color:#2a97f7;
  grid-column-start: 1;
    grid-column-end: 6;
  text-align: center;
}
.bannertext{
    margin: 10px 20px auto 0px;
    line-height: 1.25;
    font-weight: 300;
    font-size: 1.4em;
    grid-column-start: 1;
    grid-column-end: 4;
}
.bannertextfull{
    margin: 0px 40px 0px 40px;
    line-height: 1.25;
    font-weight: 300;
    font-size: 1.4em;
    grid-column-start: 1;
    grid-column-end: 12;
}
.banner-content{
    font-size: 1.2em;
    line-height: 1.4em;
}

.bannerimage{
  grid-column-start: 4;
  grid-column-end: 6;
  margin-top:20px;
}

.banner-right{
  grid-column-start: 4;
  grid-column-end: 6;
  margin:auto;
  width: 75%;
}

hr{
  margin:.5rem 0 1.25rem 0;
}

@media only screen and (max-width: 1023px) {
    .banner-right{
      display:none;
    }
    .bannerimage{
      display:none;
    }
    .bannertext{
      grid-column-start: 1;
      grid-column-end: 6;
      margin: 40px 40px 40px 0;
    }
  }
</style>
