<template>
  <section>
    <div id="get-more-container" class="has-text-centered has-text-weight-semibold">
      <div v-if="getMoreAmount > -1">
      <div id="get-more-amount">
        <vue-numeric v-bind:read-only=true currency="$" v-bind:precision=0 v-bind:value="getMoreAmount"></vue-numeric>
        <div id="get-more-label">Your <span v-html="getBrandName"></span> Amount</div>
      </div>
      <p class="has-text-left has-text-weight-normal">
        Wouldn&apos;t you like to have another <vue-numeric v-bind:read-only=true currency="$" v-bind:precision=0 v-bind:value="getMoreAmount"></vue-numeric> to use during retirement?
        We can show you how!
      </p>
      <p class="has-text-left has-text-weight-normal is-size-7" style="margin-top: 15px;">
        Your information is safe with us.  We value you as a client and never sell any of the information you provide.  We only use your information internally to provide the best retirement advice in the industry.
      </p>
      </div>
      <div v-else>
        <mike-pad id="get-more-spinner" size="120px"></mike-pad>
        <p class="is-size-7" style="margin-top: 10px;">Please wait while we find your best retirement value.</p>
        <h1 class="contactcta"  v-html="getContentSnippet('contact-teaser-cta')"></h1>
      </div>
    </div>
    <div id="email-teaser-form">
      <form ref="form" @submit.prevent="onSubmit">
        <div class="panel">
          <div class="panel-block">
            <section class="content-section-full">
              <b-field label="Your Name">
                <b-input v-model="retireeNameInput" data-vv-name="retireeNameInput" v-validate="'required'" ></b-input>
              </b-field>
              <span v-show="errors.has('retireeNameInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('retireeNameInput') }}</span>
              <b-field label="Your Email Address">
                <b-input v-model="emailAddressInput" data-vv-name="emailAddressInput" v-validate="'required|email'" ></b-input>
              </b-field>
              <span v-show="errors.has('emailAddressInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('emailAddressInput') }}</span>
              <label class="label">Phone Number</label>
              <vue-tel-input v-model="phoneNumberInput" :preferredCountries="['us', 'ca']" data-vv-name="phoneNumberInput" v-validate="'required'"></vue-tel-input>
              <span v-show="errors.has('phoneNumberInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('phoneNumberInput') }}</span>
            </section>
          </div>
          <div class="panel-block">
            <button :disabled="errors.any()" class="button is-primary submitbtn">Yes, show me the details</button>
          </div>
          <!-- <div class="panel-block is-size-7">
          <div class="panel-block is-size-7" style="display: none;">
            <a v-bind:href="sampleReportBaseUrl + '/public/quickstart/sample.pdf?key=' + sampleReportHash + '&apiKey=' + apiKey">Download Sample Report</a>
          </div>
          <div class="panel-block is-size-7" style="padding-bottom: 15px;">
            <a @click="cancelModal">No thanks, I prefer to give the government as much money as possible.</a>
          </div> -->
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import VueNumeric from 'vue-numeric'
import { mapGetters, mapActions } from 'vuex'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import MikePad from '@/components/Mikepad'
import VueTelInput from 'vue-tel-input'

const monthlyApiService = new MonthlyApiService()

export default {
  inject: ['$validator'],
  data () {
    return {
      emailAddressInput: null,
      retireeNameInput: null,
      phoneNumberInput: null,
      submitted: false,
      sampleReportBaseUrl: process.env.VUE_APP_QS_URL
    }
  },
  components: {
    VueNumeric,
    VueTelInput,
    MikePad
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getMoreAmount',
      'getBrandName',
      'clientEmail',
      'clientName',
      'clientPhoneNumber',
      'sampleReportHash',
      'apiKey',
      'quickstartResults'
    ])
  },
  watch: {
    emailAddressInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientEmail(newValue)
      }
    },
    retireeNameInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientName(newValue)
      }
    },
    phoneNumberInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientPhoneNumber(newValue)
      }
    },
    clientEmail: function () {
      this.emailAddressInput = this.clientEmail
    },
    clientName: function () {
      this.retireeNameInput = this.clientName
    },
    clientPhoneNumber: function () {
      this.phoneNumberInput = this.clientPhoneNumber
    }
  },
  methods: {
    ...mapActions({
      updateClientEmail: 'updateClientEmail',
      updateClientName: 'updateClientName',
      updateClientPhoneNumber: 'updateClientPhoneNumber',
      setGetMoreTeaserModalActive: 'setGetMoreTeaserModalActive',
      updateQuickstartResults: 'updateQuickstartResults',
      moveStep: 'moveStep'
    }),
    onSubmit: function () {
      this.submitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          this.setGetMoreTeaserModalActive(false)
          monthlyApiService.updateContact().then((response) => {
            this.$store.dispatch('updateQuickstartRequestFromResponse', response.data.payload)
          }
          )
          this.moveStep('2')
          this.$router.push({ path: 'results' })
        }
      })
    },
    cancelModal: function () {
      this.setGetMoreTeaserModalActive(false)
    }
  },
  mounted () {
    this.emailAddressInput = this.clientEmail
    this.retireeNameInput = this.clientName
    this.phoneNumberInput = this.clientPhoneNumber
  },
  async created () {
    this.emailAddressInput = this.clientEmail
    this.retireeNameInput = this.clientName
    this.phoneNumberInput = this.clientPhoneNumber
    if (Object.keys(this.quickstartResults).length === 0) {
      await monthlyApiService.startQuickOptimize().then((response) => {
        this.$store.dispatch('updateQuickstartResults', response.data.payload)
      })
    }
    monthlyApiService.getQuickResults().then(() => {
      monthlyApiService.getQuickSensitivityResults().then(() => {
      })
    })
  }
}
</script>

<style scoped>
  #email-teaser-form .label {
    color: #363636;
  }

  section {
    background-color: #ffffff;
  }

  #get-more-container {
    background-color: #209cee;
    color: #ffffff;
    padding: 15px;
  }

  #get-more-amount {
    font-size: 50px;
  }
  #get-more-label {
    font-size: 18px;
    padding: 0px 15px 10px 15px;
  }
  #get-more-spinner {
    padding-left: 180px;
    color: #ffffff;
  }
  .vue-tel-input{
    background-color: white;
    border-radius: 32px !important;
    height: 47px;
    border: 1px solid #dbdbdb !important;
  }
  .vue-tel-input > input[type="tel"] {
    border-radius: 30px !important;
}

</style>
