const config = {
  bucket: {
    bucketSlug: process.env.VUE_APP_COSMIC_BUCKET || 'quickstart',
    readKey: process.env.VUE_APP_COSMIC_READ_KEY,
    writeKey: process.env.VUE_APP_COSMIC_WRITE_KEY
  },
  object_type: document
    .getElementById('quickstart-widget')
    .getAttribute('cosmic-objects')
}

export default config
