<template>
  <section>
    <div class="savingssection panel">
      <p v-show="!hidePanelHeading" class="columnsuperhead">Step 3:</p>
      <p v-show="!hidePanelHeading" class="panel-heading columnhead">Savings</p>
      <div class="panel-block">
        <section class="content-section-full">
          <div class="columns">
            <div class="column">
              <div id="savings-taxable">
              <label class="label">Taxable Balance <b-tooltip type="is-info" label="Taxable accounts include bank accounts, money market mutual funds and investment accounts.  Enter the total balance for all of your taxable accounts." position="is-left" multilined><i class="fas fa-info-circle moreinfo"></i></b-tooltip></label>
              <b-field>
                  <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your taxable savings balance" v-model.number="taxableBalanceInput" data-vv-name="taxable balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
                </b-field>
              <span v-show="errors.has('taxable balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('taxable balance', 'step3') }}</span>
              </div>
              <div id="savings-taxdeferred">
              <label class="label">Tax Deferred Balance <b-tooltip type="is-info" label="Tax-deferred accounts include those on which taxes are paid when funds are withdrawn. Example are 401(k) and 403(b) accounts.  Enter the total balance for all of your tax-deferred accounts." position="is-left" multilined><i class="fas fa-info-circle moreinfo"></i></b-tooltip></label>
              <b-field>
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your tax deferred savings balance" v-model.number="taxDeferredBalanceInput" data-vv-name="tax deferred balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('tax deferred balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('tax deferred balance', 'step3') }}</span>
              </div>
              <div id="savings-taxexempt">
              <label class="label">Taxable Exempt Balance <b-tooltip type="is-info" label="Tax-free accounts include those on which taxes have already been paid, such as Roth IRA, Roth 401(k), Roth 403(b), and health savings accounts.  Enter the total balance of all of your tax-free accounts." position="is-left" multilined><i class="fas fa-info-circle moreinfo"></i></b-tooltip></label>
              <b-field>
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your tax exempt savings balance" v-model.number="taxExemptBalanceInput" data-vv-name="tax exempt balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('tax exempt balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('tax exempt balance', 'step3') }}</span>
              </div>
              <div id="savings-annuitybalance">
              <label class="label">Annuity Balance <b-tooltip type="is-info" label="An annuity is a contractual financial product that pays out a guaranteed stream of income.  Enter the balance of any annity you've purchased on which payments have not yet begun. If payments have begun on an annuity you've purchased, do not enter the balance here, but include the monthly payment amount with additional retirement income in the Your Household Retirement Income section." position="is-left" multilined><i class="fas fa-info-circle moreinfo"></i></b-tooltip></label>
              <b-field>
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter the balance of all your annuities" v-model.number="annuityBalanceInput" data-vv-name="annuity balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('annuity balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('annuity balance', 'step3') }}</span>
              </div>
              <div id="savings-inheritedira">
              <label class="label">Inherited IRA Balance <b-tooltip type="is-info" label="An inherited IRA is an account that is opened when an individual inherits an IRA or employer-sponsored retirement plan after the original owner dies." position="is-left" multilined><i class="fas fa-info-circle moreinfo"></i></b-tooltip></label>
              <b-field>
                <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter balance of inherited IRAs" v-model.number="inheritedIraBalanceInput" data-vv-name="inherited IRA balance" data-vv-scope="step3" v-validate="'min_value:0'" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('inherited IRA balance', 'step3')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('inherited IRA balance', 'step3') }}</span>
            </div>
          </div>
          </div>
        </section>
      </div>
    </div>
    <!-- / div END -->
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      taxableBalanceInput: this.taxableBalance,
      taxDeferredBalanceInput: this.taxDeferredBalance,
      taxExemptBalanceInput: this.taxExemptBalance,
      annuityBalanceInput: this.annuityBalance,
      inheritedIraBalanceInput: this.inheritedIraBalance,
      options: {
        number: {
          prefix: '$',
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 0
        },
        v_number_required: {
          required: true
        }
      }
    }
  },
  components: {
  },
  watch: {
    taxableBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxableBalance(newValue)
      }
    },
    taxDeferredBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxDeferredBalance(newValue)
      }
    },
    taxExemptBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxExemptBalance(newValue)
      }
    },
    annuityBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateAnnuityBalance(newValue)
      }
    },
    inheritedIraBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateInheritedIraBalance(newValue)
      }
    }
  },
  computed: {
    ...mapGetters([
      'taxableBalance',
      'taxDeferredBalance',
      'taxExemptBalance',
      'annuityBalance',
      'inheritedIraBalance'
    ])
  },
  methods: {
    ...mapActions({
      updateTaxableBalance: 'updateTaxableBalance',
      updateTaxDeferredBalance: 'updateTaxDeferredBalance',
      updateTaxExemptBalance: 'updateTaxExemptBalance',
      updateAnnuityBalance: 'updateAnnuityBalance',
      updateInheritedIraBalance: 'updateInheritedIraBalance'
    })
  },
  mounted () {
    this.taxableBalanceInput = (this.taxableBalance === 0) ? null : this.taxableBalance
    this.taxDeferredBalanceInput = (this.taxDeferredBalance === 0) ? null : this.taxDeferredBalance
    this.taxExemptBalanceInput = (this.taxExemptBalance === 0) ? null : this.taxExemptBalance
    this.annuityBalanceInput = (this.annuityBalance === 0) ? null : this.annuityBalance
    this.inheritedIraBalanceInput = (this.inheritedIraBalance === 0) ? null : this.inheritedIraBalance
  },
  created () {
  }
}
</script>

<style scoped>
#savings-taxable{
  margin-bottom:25px;
}
#savings-taxdeferred{
  margin-bottom:25px;
}
#savings-taxexempt{
  margin-bottom:25px;
}
#savings-annuitybalance{
  margin-bottom:25px;
}
#savings-inheritedira{
  margin-bottom:25px;
}
</style>
