<template>
  <div class="panel">
    <p v-show="!hidePanelHeading" class="columnsuperhead">Step 1:</p>
    <p v-show="!hidePanelHeading" class="panel-heading columnhead">Basic Info</p>
    <div class="panel-block">
      <section class="content-section-full">
        <div class="columns" v-if="isInternalTool">
          <div class="column is-one-fourth">
            <b-field label="Internal Reference ID">
              <b-input v-model="importReferenceIdInput" placeholder="Example: ABC123" />
            </b-field>
          </div>
        </div>
        <div class="columns">
            <div class="column is-one-fourth">
              <div :class="{ 'focus': isIncomeEdit }" :style="[isIncomeEdit ? focusStyle : {}]">
                  <b-field label="Are you married?" id="household-type">
                    <b-field>
                      <b-radio-button v-model="isMarriedInput" native-value=true ><span class="paddingfix">Yes</span></b-radio-button>
                      <b-radio-button v-model="isMarriedInput" native-value=false ><span class="paddingfix">No</span></b-radio-button>
                    </b-field>
                  </b-field>
                </div>
            </div>
            <div class="column is-one-fourth">
              <div id="household-age">
              <b-field label="Current Age" :layout="layout">
                <b-input v-model="clientAgeInput" placeholder="Example: 45" data-vv-name="current age" data-vv-scope="step1" v-validate="'required|numeric|min_value:' + getAgeMin +'|max_value:' + getAgeMax"  ></b-input>
              </b-field>
              <span v-show="errors.has('current age', 'step1')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('current age', 'step1') }}</span>
              </div>
            </div>
            <div class="column is-one-fourth" v-show="isMarried">
              <div v-show="isMarried">
                <b-field label="Partner's Current Age">
                  <b-input v-model="partnerAgeInput" placeholder="Example: 45" data-vv-name="partner age" data-vv-scope="step1" v-validate="{ rules: { required: spouseRequired, numeric: true, min_value: getAgeMin, max_value: getAgeMax } }" />
                </b-field>
                <span v-show="errors.has('partner age', 'step1')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('partner age', 'step1') }}</span>
              </div>
            </div>
            <div class="column is-one-fourth">
              <div id="household-retirementage">
              <b-field label="Age at Retirement">
                <b-input v-model="clientRetirementAgeInput" placeholder="Example: 62" data-vv-name="retirement age" data-vv-scope="step1" v-validate="'required|numeric|min_value:' + getRetirementAgeMin +'|max_value:' + getRetirementAgeMax"/>
              </b-field>
              <span v-show="errors.has('retirement age', 'step1')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('retirement age', 'step1') }}</span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-third" v-show="getPlanType === null">
              <div v-show="getPlanType === null" id="household-annualcontributions">
                <b-field label="Annual Retirement Contributions">
                  <cleave class="input" inputmode="numeric" pattern="\$[\d,]*" placeholder="Enter your tax deferred annual contributions" v-model="taxDeferredContributionInput" data-vv-name="tax deferred contribution" data-vv-scope="step1" v-validate="options.v_number_required" :options="options.number"></cleave>
                </b-field>
                <span v-show="errors.has('tax deferred contribution', 'step1')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('tax deferred contribution', 'step1') }}</span>
              </div>
            </div>
            <div class="column is-one-third">
              <div id='investment-risk-container' :class="{ 'focus': isRiskEdit }" :style="[isRiskEdit ? focusStyle : {}]">
                <label class="label">Investment Profile <b-tooltip type="is-info" label="Investment Profile is the related to the amount of risk you are willing to assume in your investments." position="is-left" multilined><i class="fas fa-info-circle"></i></b-tooltip></label>
                <b-field>
                  <b-select id="investment-risk-control" placeholder="Select your investment profile" v-model="investmentProfileInput" data-vv-name="investment profile" data-vv-scope="step1" v-validate="'required'">
                    <option
                      v-for="option in allocationStrategyOptions"
                      :value="option.strategyId"
                      :key="option.strategyId">
                      {{ option.riskTolerance }}
                    </option>
                  </b-select>
                </b-field>
                <span v-show="errors.has('investmentProfileInput', 'step1')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('investmentProfileInput', 'step1') }}</span>
              </div>
            </div>
          </div>
      </section>
    </div>
  </div>
  <!-- / div END -->

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      layout: 'horizontal',
      importReferenceIdInput: null,
      isMarriedInput: (this.married) ? 'true' : 'false',
      clientAgeInput: null,
      clientRetirementAgeInput: null,
      partnerAgeInput: null,
      partnerRetirementAgeInput: null,
      investmentProfileInput: null,
      taxDeferredContributionInput: null,
      allocationStrategyOptions: null,
      options: {
        number: {
          prefix: '$',
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 0
        },
        v_number_required: {
          required: true
        }
      },
      focusStyle: {
        'padding': '10px',
        'border-radius': '4px'
      }
    }
  },
  watch: {
    importReferenceIdInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateImportReferenceId(newValue)
      }
    },
    isMarriedInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateIsMarried(newValue)
        this.updateDirtyForm(true)
      }
    },
    clientAgeInput (newValue, oldValue) {
      if (newValue !== null && newValue !== oldValue) {
        this.updateClientAge(newValue)
        this.updateDirtyForm(true)
      }
    },
    clientRetirementAgeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientRetirementAge(newValue)
      }
    },
    partnerAgeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePartnerAge(newValue)
      }
    },
    partnerRetirementAgeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePartnerRetirementAge(newValue)
      }
    },
    investmentProfileInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateInvestmentProfile(newValue)
      }
    },
    taxDeferredContributionInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxDeferredContribution(newValue)
      }
    },
    allocationStrategies: function () {
      this.initAllocationStrategyOptions()
    }
  },
  computed: {
    ...mapGetters([
      'isInternalTool',
      'importReferenceId',
      'married',
      'isMarried',
      'clientAge',
      'clientRetirementAge',
      'partnerAge',
      'partnerRetirementAge',
      'investmentProfile',
      'taxDeferredContribution',
      'allocationStrategies',
      'getPlanType',
      'getEditMode',
      'getAgeMin',
      'getAgeMax',
      'getRetirementAgeMin',
      'getRetirementAgeMax'
    ]),
    spouseRequired: function () {
      if (this.married === 'true') {
        return true
      } else {
        return false
      }
    },
    isRiskEdit: function () {
      return (this.getEditMode === 'risk')
    },
    isIncomeEdit: function () {
      return (this.getEditMode === 'income')
    }
  },
  methods: {
    ...mapActions({
      updateImportReferenceId: 'updateImportReferenceId',
      updateIsMarried: 'updateIsMarried',
      updateClientAge: 'updateClientAge',
      updateClientRetirementAge: 'updateClientRetirementAge',
      updatePartnerAge: 'updatePartnerAge',
      updatePartnerRetirementAge: 'updatePartnerRetirementAge',
      updateTaxDeferredContribution: 'updateTaxDeferredContribution',
      updateInvestmentProfile: 'updateInvestmentProfile',
      updateDirtyForm: 'updateDirtyForm'
    }),
    initAllocationStrategyOptions: function () {
      let options = []
      this.allocationStrategies.forEach(strategy => {
        options.push({ strategyId: strategy.strategyId, riskTolerance: strategy.riskTolerance })
      })
      this.allocationStrategyOptions = options
    }
  },
  mounted () {
    this.importReferenceIdInput = this.importReferenceId
    this.isMarriedInput = (this.married) ? 'true' : 'false'
    this.clientAgeInput = (this.clientAge === 0) ? null : this.clientAge
    this.isMarriedInput = (this.married) ? 'true' : 'false'
    this.clientRetirementAgeInput = (this.clientRetirementAge === 0) ? null : this.clientRetirementAge
    this.partnerAgeInput = (this.partnerAge === 0) ? null : this.partnerAge
    this.partnerRetirementAgeInput = (this.partnerRetirementAge === 0) ? null : this.partnerRetirementAge
    this.taxDeferredContributionInput = this.taxDeferredContribution
    this.initAllocationStrategyOptions()
    this.investmentProfileInput = this.investmentProfile
  },
  created () {
  }
}
</script>

<style scoped>
#household-type{
  margin-bottom:25px;
  font-size:25px;
}
#household-age{
  margin-bottom:25px;
}
#household-retirementage{
  margin-bottom:25px;
}
#household-annualcontributions{
  margin-bottom:25px;
}
.paddingfix{
    padding-left: 1.25em;
    padding-right: 1.25em;
}
</style>
