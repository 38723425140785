<template>
  <div>
    <img src="@/assets/longevity_icon.png" />
    <h2 class="title is-uppercase">Longevity</h2>
    <div>
      <font-awesome-icon :icon="[ 'far', 'arrow-alt-circle-up']" size="3x" /><span class="indicator-value"><vue-numeric v-bind:read-only=true v-bind:precision=2 v-bind:value="getLongevityIndicatorValue"></vue-numeric> years</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueNumeric from 'vue-numeric'

export default {
  data () {
    return {
    }
  },
  components: {
    VueNumeric
  },
  computed: {
    ...mapGetters([
      'getLongevityIndicatorValue'
    ])
  }
}
</script>

<style scoped>
.title {
  font-size: 1.5em;
}
.indicator-value {
  font-size: 1.5em;
  margin-left: 10px;
}
</style>
