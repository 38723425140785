<template>
  <div class="container" v-if="typeof(quickstartRequest.requestId) !== 'undefined' && quickstartRequest.requestId !== null">
      <form ref="form" @submit.prevent="onSubmit">
          <div id="top-container" class="container columns is-desktop">
            <div id="spending-container" class="column">
              <spending />
            </div>
            <!-- <div id="promo-container" class="column">
              <Tweet :id="'951501872736632833'" :options="{ align: 'center', cards: 'hidden', dnt: true, width: 500, theme: 'light' }"/>
            </div> -->
          </div>
          <div id="input-container" class="container columns is-desktop">
              <div class="column is-one-third-desktop has-right-border">
                  <demographics ref="demographics" />
              </div>
              <div class="column is-one-third-desktop has-right-border">
                <savings v-show="getPlanType === null"></savings>
                <savings-plan v-show="getPlanType !== null"></savings-plan>
              </div>
              <div class="column is-one-third-desktop">
                  <income />
                  <div id="calculate-button-container">
                      <button :disabled="errors.any()" class="button is-primary is-fullwidth">Calculate Your Retirement Strategy</button>
                  </div>
              </div>
          </div>
      </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Demographics from '@/components/Demographics'
import Savings from '@/components/Savings'
import SavingsPlan from '@/components/SavingsPlan'
import Income from '@/components/Income'
import Spending from '@/components/SpendingSlider'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import { Tweet, Moment, Timeline } from 'vue-tweet-embed'

const monthlyApiService = new MonthlyApiService()

export default {
  name: 'quickstartForm2',
  components: {
    Demographics,
    Savings,
    SavingsPlan,
    Income,
    Spending,
    Timeline,
    Moment,
    Tweet
  },
  data () {
    return {
      submitted: false
    }
  },
  computed: {
    ...mapGetters([
      'getPlanType',
      'quickstartRequest'
    ])
  },
  methods: {
    onSubmit () {
      this.$tours['inputTour'].stop()
      monthlyApiService.startQuickstart().then((response) => {
        this.$store.dispatch('updateQuickstartRequestFromResponse', response.data.payload)
        monthlyApiService.startOptimize().then((response) => {
          this.$store.dispatch('updateQuickstartResults', response.data.payload)
          this.$router.push({ path: 'results' })
        }
        )
      }
      )
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
#top-container, #input-container {
  margin: 10px;
}
#input-container, #spending-container {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: 1px solid black;
}
#spending-container {
  margin-right: 5px;
}
#promo-container {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  margin-left: 5px;
}
#calculate-button-container {
  padding: 0px 15px;
}

@media (min-width: 769px) {
  .has-right-border {
    border-right: 1px solid #dbdbdb;
  }
}

@media (max-width: 768px) {
  .has-right-border {
    border-bottom: 1px solid #dbdbdb;
  }
}
</style>
