<template>
  <div class="container" id="get-more-container">
    <div class="columns is-vcentered is-centered">
      <div class="column">
        <div class="container has-text-centered has-text-weigh-semibold" id="get-more">
          <div id="get-more-amount" v-if="getMoreAmount > -1"  v-bind:class="getAmountClass">
            <vue-numeric v-bind:read-only=true currency="$" v-bind:precision=0 v-bind:value="getMoreAmount"></vue-numeric>
            <div id="get-more-label">Your <span v-html="getBrandName"></span> Amount</div>
          </div>
          <div id="get-more-spinner" v-else>
            <circle-8 size="120px"></circle-8>
          </div>
        </div>
        <div class="has-text-centered" style="padding-top: 25px;">
          <a id="download-report-button" class="button is-primary rounded" v-show="getMoreAmount > -1 && isInternalTool"
            :href="reportUrl">
            <i class="fas fa-download" style="margin-right:10px;"></i> Download Report
          </a>
          <transition name="flip" mode="in-out">
            <a :key="flip" id="email-report-button" class="button is-primary" v-show="getMoreAmount > -1 && !isInternalTool"
              @click="setEmailReportModalActive(true)">
              Send My Personalized Report
            </a>
          </transition>
        </div>
      </div>
    </div>
    <div id="get-more-content" v-html="getContentSnippet('get-more')">
    </div>
  </div>
</template>

<script>
import VueNumeric from 'vue-numeric'
import { mapGetters, mapActions } from 'vuex'
import { Circle8 } from 'vue-loading-spinner'
export default {
  data () {
    return {
      flip: false
    }
  },
  components: {
    VueNumeric,
    Circle8
  },
  methods: {
    ...mapActions({
      setEmailReportModalActive: 'setEmailReportModalActive'
    }),
    flipButton () {
      this.flip = !this.flip
    }
  },
  computed: {
    ...mapGetters([
      'getMoreAmount',
      'getContentSnippet',
      'getContentSnippetNoHtml',
      'getBrandName',
      'getEmailReportModalActive',
      'isInternalTool',
      'requestHash'
    ]),
    getAmountClass: function () {
      if (this.getMoreAmount < 1000000) {
        return 'get-more-under-1m'
      } else {
        return 'get-more-over-1m'
      }
    },
    reportUrl: function () {
      return '/solver/public/quickstart/report.pdf?key=' + this.requestHash
    }
  },
  timers: {
    flipButton: {
      time: 15000,
      autostart: true,
      repeat: true
    }
  }
}
</script>

<style scoped>
  #get-more-container .navbar-item {
    font-size: 1.5rem;
  }
  #get-more-container {
    padding-left: 20px;
  }
  #get-more {
    background-position: center;
    background-image: url('../assets/getmorebg1.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 300px;
    width: 300px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border: 0px solid #ffffff;
  }
  #email-report-button {
    width: 400px;
    font-size: 1.50rem;
  }
  .get-more-under-1m {
    font-size: 50px;
  }
  .get-more-over-1m {
    font-size: 45px;
  }
  .get-more-over-1m > span {
    padding-bottom: 25px;
  }
  #get-more-amount {
    padding-top: 75px;
  }
  #get-more-label {
    font-size: 18px;
    padding: 0px 25px;
  }
  #get-more-spinner {
    padding-top: 60px;
    padding-left: 90px;
  }
  #get-more-content {
    padding: 10px 0px;
  }
  .flip-enter-active {
    transition: all .4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  .flip-leave-active {
    transition: all .6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .flip-enter, .flip-leave-to {
    transform: scaleY(0) translateZ(0);
    opacity: 0;
  }
</style>
