<template>
  <div id="generic-modal">
    <div class="panel">
        <div class="panel-heading">{{ title }}</div>
        <div class="panel-block">
          <section class="content-section-full">
            <h3>{{ message }}</h3>
          </section>
          <section>
            <circle-8 size="60px"></circle-8>
          </section>
        </div>
      </div>
  </div>
</template>

<script>
import { Circle8 } from 'vue-loading-spinner'

export default {
  props: {
    title: {
      type: String,
      default: 'Please Wait...'
    },
    message: {
      type: String,
      default: 'Thank you for your patience as we complete your request.'
    }
  },
  components: {
    Circle8
  }
}
</script>
