<template>
  <div id="investment-growth-container">
    <h2 class="sectionheader">What are your retirement resources?</h2>
    <div class="columns">
      <div class="column">
        <div v-if="getMoreAmount > -1">
          <div id="investment-growth-chart-container">
            <investment-growth-line-chart :chart-data="getInvestmentGrowthChartData" :options="options" :width="300" :height="300"></investment-growth-line-chart>
          </div>
        </div>
      </div>
      <div id="investment-growth-content" class="column" v-html="getContentSnippet('investment-growth-content')">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InvestmentGrowthLineChart from '@/components/charts/InvestmentGrowthLineChart.js'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

class InvestmentGrowthChartItem {
  constructor (label, data, borderColor) {
    this.label = label
    this.data = data
    this.borderColor = borderColor
  }
}

class BoundaryChartItem {
  constructor (data, borderColor) {
    this.label = null
    this.data = data
    this.borderColor = borderColor
    this.borderDash = [5, 5]
  }
}

export default {
  data () {
    return {
      balanceMap: null,
      label: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: 'bottom'
        },
        elements: {
          point: {
            radius: this.customRadius,
            display: true,
            pointStyle: this.customPointStyle,
            backgroundColor: this.customBackgroundColor
          }
        },
        scales: {
          xAxes: [{
            id: 'xaxis',
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Age'
            },
            ticks: {
              autoSkip: false
            }
          }],
          yAxes: [{
            id: 'default',
            ticks: {
              callback: function (label, index, labels) {
                if (label) {
                  return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(label)
                }
              }
            }
          }]
        },
        plugins: {
          datalabels: {
            display: this.showDataLabels,
            align: 'left',
            offset: 10,
            formatter: function (value, context) {
              return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value)
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getOptimalResults',
      'getMoreAmount',
      'clientAge',
      'getEstimatedSavings'
    ]),
    getInvestmentGrowthChartData () {
      let chartData = {}
      let datasets = []
      let labels = []

      let upperBoundDataset = new BoundaryChartItem([], ['rgba(3, 1, 26, .2)'])
      let lowerBoundDataset = new BoundaryChartItem([], ['rgba(3, 1, 26, .2)'])
      let growthDataset = new InvestmentGrowthChartItem('Estimated Savings Growth', [], ['rgba(3, 1, 26, 1)'])
      let estimatedSavings = this.getEstimatedSavings
      for (const age in estimatedSavings) {
        // adjust dataset so data is alway available for marker icons
        // for larger datasets only use even year data
        if (age === this.ClientAge || age === '67' ||
          Object.keys(estimatedSavings).length <= 30 || age % 2 === 0) {
          labels.push(age)
          let balance = estimatedSavings[age]
          growthDataset.data.push(balance)
          upperBoundDataset.data.push(Math.floor(balance * 1.06))
          lowerBoundDataset.data.push(Math.floor(balance - (balance * 0.06)))
        }
      }

      datasets.push(lowerBoundDataset)
      datasets.push(growthDataset)
      datasets.push(upperBoundDataset)

      chartData.labels = labels
      chartData.datasets = datasets
      return chartData
    }
  },
  components: {
    InvestmentGrowthLineChart,
    ChartJsPluginDataLabels
  },
  methods: {
    customRadius (ctx) {
      if (ctx.datasetIndex !== 1) return 0
      let idx = ctx.dataIndex
      let label = ctx.chart.scales.xaxis.ticks[idx]
      let fra = (this.clientAge < 60) ? '67' : '66'
      return (label === '62' || label === fra || label === '70') ? 10 : 0
    },
    customPointStyle (ctx) {
      if (ctx.datasetIndex !== 1) return null
      let idx = ctx.dataIndex
      let label = ctx.chart.scales.xaxis.ticks[idx]
      let fra = (this.clientAge < 60) ? '67' : '66'
      if (label === '62' || label === fra || label === '70') {
        if (label === '62') return 'rect'
        if (label === fra) return 'triangle'
        return 'circle'
      }
      return null
    },
    customBackgroundColor (ctx) {
      if (ctx.datasetIndex !== 1) return null
      let idx = ctx.dataIndex
      let label = ctx.chart.scales.xaxis.ticks[idx]
      let fra = (this.clientAge < 60) ? '67' : '66'
      if (label === '62' || label === fra || label === '70') {
        if (label === '62') return 'rgba(3, 1, 26, 1)'
        if (label === fra) return 'rgba(213, 216, 26, 25)'
        return 'rgba(68, 181, 114, 1)'
      }
      return null
    },
    showDataLabels (ctx) {
      if (ctx.datasetIndex !== 1) return false
      let idx = ctx.dataIndex
      let label = ctx.chart.scales.xaxis.ticks[idx]
      let fra = (this.clientAge < 60) ? '67' : '66'
      return (label === '62' || label === fra || label === '70')
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
#investment-growth-chart-container{
  background-color: #ffffff;
    padding: 15px 10px;
    border-radius:25px;
}
</style>
