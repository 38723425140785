<template>
  <div class="container columns" id="get-more-container" style="padding-bottom: 50px;">
      <!-- <div class="column"> -->
        <!-- remove v-if to start showing Learn More button -->
        <!-- <router-link v-if="1==2" to="/promotion" class="navbar-item button is-link">Learn More<font-awesome-icon size="2x" icon="angle-right"  style="margin-left: 20px;"/></router-link>
      </div> -->
      <div class="column is-three-fifths-desktop is-half-tablet">
        <div class="getmoretext" id="get-more-content" v-html="getContentSnippet('get-more')"></div>
        <div class="columns is-desktop" style="padding-top:20px">
          <div class="column is-one-third-desktop">
            <div id="recalculate">
                <router-link to="/home" class="navbar-item button is-link recalculatebtn"><i class="fas fa-backward pushleft" style="margin-right:10px;"></i> Recalculate</router-link>
            </div>
          </div>
          <div class="column is-two-third-desktop">
            <div id="personalizedreport">
              <div class="columns">
                <div class="column is-one-half-desktop">
                  <button v-if="isInternalTool" v-show="getMoreAmount > -1" type="button" class="button is-primary rounded" @click="setDownloadReportModalActive(true)"><i class="fas fa-download" style="margin-right:10px;"></i> Download Report</button>
                </div>
                <div class="column is-one-half-desktop">
                  <a id="import-button" class="button is-primary rounded" v-show="getMoreAmount > -1 && isInternalTool"
                    :href="importUrl">
                    <i class="fas fa-upload" style="margin-right:10px;"></i> Import Household
                  </a>
                </div>
              </div>
              <transition name="flip" mode="in-out">
                <a :key="flip" id="email-report-button" class="button is-primary rounded" v-show="getMoreAmount > -1 &&  !isInternalTool"
                  @click="setEmailReportModalActive(true)">
                  <i class="fas fa-download" style="margin-right:10px;"></i> Get Your Free Personalized Report
                </a>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="column" id="freereportbutton">
        <div class="container has-text-centered has-text-weigh-semibold" id="get-more">
          <div id="get-more-amount" v-if="getMoreAmount > -1"  v-bind:class="getAmountClass">
            <ICountUp :endVal="getMoreAmount" :delay="icountupDelay" :options="getCountUpOptions"/>
            <!-- <vue-numeric v-bind:read-only=true currency="$" v-bind:precision=0 v-bind:value="getMoreAmount"></vue-numeric> -->
            <div id="get-more-label">Your <span v-html="getBrandName"></span> Amount</div>
          </div>
          <div id="get-more-spinner" v-else>
            <circle-8 size="120px"></circle-8>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import VueNumeric from 'vue-numeric'
import { mapGetters, mapActions } from 'vuex'
import { Circle8 } from 'vue-loading-spinner'
import ICountUp from 'vue-countup-v2'

export default {
  data () {
    return {
      flip: false,
      icountupDelay: 0,
      icountupOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '$',
        suffix: '',
        duration: 2
      }
    }
  },
  components: {
    VueNumeric,
    Circle8,
    ICountUp
  },
  methods: {
    ...mapActions({
      setEmailReportModalActive: 'setEmailReportModalActive',
      setDownloadReportModalActive: 'setDownloadReportModalActive'
    }),
    flipButton () {
      this.flip = !this.flip
    }
  },
  computed: {
    ...mapGetters([
      'getMoreAmount',
      'getContentSnippet',
      'getContentSnippetNoHtml',
      'getBrandName',
      'getEmailReportModalActive',
      'isInternalTool',
      'requestHash'
    ]),
    getAmountClass: function () {
      if (this.getMoreAmount < 1000000) {
        return 'get-more-under-1m'
      } else {
        return 'get-more-over-1m'
      }
    },
    getCountUpOptions: function () {
      let opts = this.icountupOptions
      let startVal = Math.ceil((Math.floor(this.getMoreAmount * 0.8)) / 100) * 100
      opts.startVal = (startVal < 50000) ? 0 : startVal
      return opts
    },
    reportUrl: function () {
      return '/solver/public/quickstart/report.pdf?key=' + this.requestHash
    },
    importUrl: function () {
      return '/solver/quickstart/importhousehold/' + this.requestHash
    }
  },
  timers: {
    flipButton: {
      time: 15000,
      autostart: true,
      repeat: true
    }
  }
}
</script>

<style scoped>
  #get-more-container .navbar-item {
    font-size: 1rem;
    border-radius: 40px;
    padding: 20px;
    font-weight:700;
  }

  #get-more {
    background-position: center !important;
    background-image: url('../assets/get-more-background.png') !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 378px;
    width: 378px;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    border: 0px solid #ffffff !important;
  }

  #recalculate{
    padding-top:25px;
  }
  .pushleft{
    -webkit-animation: pushleft;
    animation: pushleft;
  }

  #email-report-button {
    font-size: 1rem;
    padding:20px;
    font-weight:700;
  }
  .get-more-under-1m {
    font-size: 50px;
    text-align: center;
  }
  .get-more-over-1m {
    font-size: 45px;
  }
  .get-more-over-1m > span {
    padding-bottom: 25px;
  }
  #get-more-amount {
    padding-top: 75px;
  }
  #get-more-label {
    font-size: 18px;
    padding: 0px 25px;
  }
  /* #get-more-spinner {
    padding-top: 60px;
    padding-left: 400px;
  } */
  #get-more-content {
    padding: 45px 0px 0px 0px;
    font-size: 1.5em;
    font-weight: 400;
  }
  .flip-enter-active {
    transition: all .4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  .flip-leave-active {
    transition: all .6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .flip-enter, .flip-leave-to {
    transform: scaleY(0) translateZ(0);
    opacity: 0;
  }

@media only screen and (max-width: 480px) {
  #get-more {
    height: 340px;
    width: 340px;
  }
}

@media only screen and (max-width: 1000px) {
  #get-more {
    height: 340px;
    width: 340px;
  }

  #get-more-spinner {
    padding-top: 60px;
    padding-left: 110px;
  }

  #get-more-container .navbar-item {
    padding-top: 10px;
  }
}
</style>
