<template>
  <div class="content has-text-centered">
    <h3></h3>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>
