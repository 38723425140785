<template>
  <div class="vertical-scale" :class="customClass">
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height">
      <g>
        <text class="scale-title" x="100" y="20">{{title}}</text>
        <rect class="scale" x="100" y="40" :width="scaleWidth" :height="scaleHeight" :fill="scaleFillColor"></rect>
        <line class="indicator" x1="90" :y1="scaleIndicatorPosition" x2="240" :y2="scaleIndicatorPosition" :stroke="scaleIndicatorColor" :stroke-width="scaleIndicatorThickness"></line>
        <text class="indicator-label" x="35" :y="scaleIndicatorPosition + 10">{{valueLabel}}</text>
        <text class="indicator-value" x="250" :y="scaleIndicatorPosition + 10">{{formattedNumber(value)}} {{valueSuffix}}</text>
        <text v-show="showMaxLabel" class="max-label" x="60" y="50">{{maxLabel}}</text>
        <text v-show="showMaxLabel" class="max-value" x="180" y="50">{{formattedNumber(max)}}</text>
        <text v-show="showMinLabel" class="min-label" x="60" :y="scaleHeight + 20">{{minLabel}}</text>
        <text v-show="showMinLabel" class="min-value" x="180" :y="scaleHeight + 20">{{formattedNumber(min)}}</text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 5000,
      required: false
    },
    valueLabel: {
      type: String,
      required: false
    },
    valueSuffix: {
      type: String,
      required: false
    },
    min: {
      type: Number,
      default: 1000,
      required: false
    },
    minLabel: {
      type: String,
      required: false
    },
    max: {
      type: Number,
      default: 10000,
      required: false
    },
    maxLabel: {
      type: String,
      required: false
    },
    options: {
      type: Object,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    customClass: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      defaultOptions: Object
    }
  },
  computed: {
    height () {
      return this.defaultOptions.layout.height
    },
    width () {
      return this.defaultOptions.layout.width
    },
    scaleHeight () {
      return this.defaultOptions.layout.height * 0.9
    },
    scaleWidth () {
      return this.defaultOptions.scale.width
    },
    scaleFillColor () {
      return this.defaultOptions.scale.color
    },
    scaleIndicatorColor () {
      return this.defaultOptions.scale.indicatorColor
    },
    scaleIndicatorThickness () {
      return this.defaultOptions.scale.indicatorThickness
    },
    scaleIndicatorPosition () {
      if (this.value < this.min) {
        return this.scaleHeight + 20
      }
      if (this.value > this.max) {
        return 40
      }
      let range = this.max - this.min
      let relativePosition = (this.value - this.min) / range
      return this.scaleHeight - (this.scaleHeight * relativePosition) + 30
    },
    showMinLabel () {
      return (this.scaleIndicatorPosition < (this.scaleHeight + 10))
    },
    showMaxLabel () {
      return (this.scaleIndicatorPosition > 50)
    }
  },
  methods: {
    mergeDefaultOptionsWithProp: function (options) {
      var result = this.defaultOptions
      for (var option in options) {
        if (options[option] !== null && typeof (options[option]) === 'object') {
          for (var subOption in options[option]) {
            if (options[option][subOption] !== undefined && options[option][subOption] !== null) {
              result[option][subOption] = options[option][subOption]
            }
          }
        } else {
          result[option] = options[option]
        }
      }
    },
    formattedNumber: function (val) {
      return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(val)
    }
  },
  created () {
    this.defaultOptions = {
      text: {
        color: 'black',
        fontSize: 10,
        textAdjustmentY: 2,
        fontFamily: 'Arial',
        textEnabled: true
      },
      scale: {
        color: '#FF0000',
        backgroundColor: '#fcf9f9',
        frameColor: 'black',
        width: 40,
        indicatorColor: 'orange',
        indicatorThickness: 3
      },
      layout: {
        height: 300,
        width: 500
      }
    }
  },
  mounted () {
    if (this.options !== null && this.options !== undefined) {
      this.mergeDefaultOptionsWithProp(this.options)
    }
  }
}
</script>

<style lang="scss" scoped>
  .vertical-scale {
    padding: 15px;
  }
  .bar {
    fill: #d44747;
  }
  .indicator-label,
  .indicator-value {
    font-size: 28px;

  }
</style>
