<template>
  <div id="allocation-container" v-if="isReady">
    <div class="columns">
      <div class="column">
        <h2 class="sectionheader">Investment Profile &amp; Protection</h2>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <div id="allocation-content" v-html="getContentSnippet('annuity-content')">
        </div>
        <button class="button is-secondary riskadjust" @click="setRiskEditMode" style="margin-top:10px;">Adjust Your Risk</button>
      </div>
      <div id="allocation-pies" class="column is-half">
        <div class="columns">
          <div class="column is-half text-center">
            <div style="width: 200px; margin: auto;">
              <h2 class="pieTitle">Typical Strategy</h2>
            <allocation-pie-chart :chartData="baseChartData" :options="chartOptions" :width="200" :height="200"></allocation-pie-chart>
            </div>
          </div>
          <div class="column is-half text-center">
            <div style="width: 200px; margin: auto;">
              <h2 class="pieTitle">Optimal Strategy</h2>
            <allocation-pie-chart :chartData="alphaChartData" :options="chartOptions" :width="200" :height="226"></allocation-pie-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column provider-content-box">
        <div id="allocation-content" v-html="getContentSnippet('annuity-provider-content')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import Chart from 'chart.js'
import AllocationPieChart from '@/components/charts/AllocationPieChart.js'

export default {
  data () {
    return {
      chartOptions: {
        responsive: false,
        maintainAspectRatio: true,
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            useRectStyle: true,
            boxWidth: 7,
            generateLabels: function (chart) {
              var helpers = Chart.helpers
              var data = chart.data
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function (label, i) {
                  var meta = chart.getDatasetMeta(0)
                  var ds = data.datasets[0]
                  var arc = meta.data[i]
                  var custom = (arc && arc.custom) || {}
                  var arcOpts = chart.options.elements.arc
                  var fill = custom.backgroundColor ? custom.backgroundColor : helpers.getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor)
                  var stroke = custom.borderColor ? custom.borderColor : helpers.getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor)
                  var bw = custom.borderWidth ? custom.borderWidth : helpers.getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth)
                  return {
                  // And finally :
                    text: ds.data[i] + '% ' + label,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: bw,
                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                    index: i
                  }
                })
              }
              return []
            }
          }
        },
        plugins: {
          datalabels: {
            // color: '#444',
            // padding: 25,
            // font: {
            //   size: '12',
            //   weight: 'bold'
            // },
            // formatter: function (value, ctx) {
            //   return ctx.chart.data.labels[ctx.dataIndex]
            // }
            display: false
          }
        }
      }
    }
  },
  components: {
    AllocationPieChart
  },
  computed: {
    ...mapGetters([
      'getContentSnippet'
    ]),
    ...mapState([
      'advisorAlpha'
    ]),
    isReady () {
      return this.advisorAlpha !== null
    },
    alphaChartData () {
      return this.createChartData(this.advisorAlpha.alphaAllocation)
    },
    baseChartData () {
      return this.createChartData(this.advisorAlpha.baseAllocation)
    }
  },
  methods: {
    ...mapActions({
      setEditMode: 'setEditMode'
    }),
    setRiskEditMode: function () {
      this.setEditMode('risk')
      this.$router.push({ path: 'home' })
    },
    createChartData: function (categoryAllocations) {
      let chartData = {
        datasets: []
      }
      let labels = []
      let chartDataset = {
        backgroundColor: [],
        data: []
      }

      categoryAllocations.forEach((category) => {
        switch (category.category) {
          case 'Stock':
            labels.push('Equities')
            chartDataset.backgroundColor.push('#216ff9')
            break
          case 'Bond':
            labels.push('Fixed Income')
            chartDataset.backgroundColor.push('#39b54a')
            break
          case 'RealEstate':
            labels.push('REITs')
            chartDataset.backgroundColor.push('#f7d239')
            break
          case 'Cash':
            labels.push(category.category)
            chartDataset.backgroundColor.push('#70c389')
            break
          case 'Currency':
            labels.push(category.category)
            chartDataset.backgroundColor.push('#f7783c')
            break
          case 'Commodities':
            labels.push(category.category)
            chartDataset.backgroundColor.push('#ad55ff')
            break
          case 'Alt':
            labels.push(category.category)
            chartDataset.backgroundColor.push('#00267c')
            break
          case 'Annuities':
            labels.push('Annuities')
            chartDataset.backgroundColor.push('#72dda2')
            break
          default:
            labels.push(category.category)
            break
        }
        chartDataset.data.push(category.targetRatio)
      })

      chartData.labels = labels
      chartData.datasets.push(chartDataset)
      return chartData
    }
  }
}
</script>
<style scoped>
.pieTitle{
  margin-bottom:10px;
  font-weight:600;
  border-bottom: 1px solid #c5c5c5;
}
.provider-content-box{
  border-top:1px solid #c5c5c5;
  margin-top:20px;
  padding-top:20px;
}
.downloadReportBtn{
  border-radius:10px;
  background-color:#72dda2;
}
.riskadjust{
    border-radius: 20px;
    border: 2px solid #3a97f7;
    background-color: #e7f2ff;
    padding: 20px;
    font-weight: 600;
  }
</style>
