<template>
  <div id="email-form">
    <form ref="form" @submit.prevent="onSubmit">
      <div class="panel">
        <h1 class="contactcta"  v-html="getContentSnippet('contact-cta')"></h1>
        <div class="panel-block">
          <section class="content-section-full">
            <label class="emaillabel">Your Name</label>
            <b-field>
              <b-input v-model="retireeNameInput" data-vv-name="retireeNameInput" v-validate="'required'" ></b-input>
            </b-field>
            <span v-show="errors.has('retireeNameInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('retireeNameInput') }}</span>
            <label class="emaillabel">Your Email Address</label>
            <b-field>
              <b-input v-model="emailAddressInput" data-vv-name="emailAddressInput" v-validate="'required|email'" ></b-input>
            </b-field>
            <span v-show="errors.has('emailAddressInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('emailAddressInput') }}</span>
            <label class="emaillabel">Phone Number</label>
            <vue-tel-input v-model="phoneNumberInput" :preferredCountries="['us', 'ca']" data-vv-name="phoneNumberInput" v-validate="'required'"></vue-tel-input>
            <span v-show="errors.has('phoneNumberInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('phoneNumberInput') }}</span>
          </section>
        </div>
        <div class="panel-block">
          <button :disabled="errors.any()" class="button is-primary" v-html="getContentSnippet('contact-button')"></button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import VueTelInput from 'vue-tel-input'

const monthlyApiService = new MonthlyApiService()

export default {
  inject: ['$validator'],
  data () {
    return {
      emailAddressInput: null,
      retireeNameInput: null,
      phoneNumberInput: null,
      submitted: false,
      redirect: this.getUseCustomThankYouPage,
      redirectUrl: this.getCustomThankYouUrl
    }
  },
  components: {
    VueTelInput
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'clientEmail',
      'clientName',
      'clientPhoneNumber',
      'getUseCustomThankYouPage',
      'getCustomThankYouUrl'
    ])
  },
  watch: {
    emailAddressInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientEmail(newValue)
      }
    },
    retireeNameInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientName(newValue)
      }
    },
    phoneNumberInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientPhoneNumber(newValue)
      }
    },
    clientEmail: function () {
      this.emailAddressInput = this.clientEmail
    },
    clientName: function () {
      this.retireeNameInput = this.clientName
    },
    clientPhoneNumber: function () {
      this.phoneNumberInput = this.clientPhoneNumber
    }
  },
  methods: {
    ...mapActions({
      updateClientEmail: 'updateClientEmail',
      updateClientName: 'updateClientName',
      updateClientPhoneNumber: 'updateClientPhoneNumber',
      setEmailReportModalActive: 'setEmailReportModalActive',
      moveStep: 'moveStep'
    }),
    onSubmit: function () {
      this.submitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          this.setEmailReportModalActive(false)
          this.moveStep('3')
          monthlyApiService.sendReport().then((response) => {
            this.$store.dispatch('updateQuickstartRequestFromResponse', response.data.payload)
            if (this.getUseCustomThankYouPage) {
              window.location = this.getCustomThankYouUrl
            }
          }
          )
        }
      })
    }
  },
  mounted () {
    this.emailAddressInput = this.clientEmail
    this.retireeNameInput = this.clientName
    this.phoneNumberInput = this.clientPhoneNumber
  },
  created () {
    this.emailAddressInput = this.clientEmail
    this.retireeNameInput = this.clientName
    this.phoneNumberInput = this.clientPhoneNumber
  }
}
</script>

<style scoped>
  #email-form .label {
    color: #ffffff;
  }
  .emaillabel{
    color:#fff;
    font-size:22px;
    font-weight:600;
  }
  .emailheading{
    color:#fff;
    font-size:32px;
    font-weight:600;
  }
  .vue-tel-input{
    background-color: white;
    border-radius: 32px !important;
    height: 47px;
    border: 1px solid #dbdbdb !important;
  }
  .vue-tel-input > input[type="tel"] {
    border-radius: 30px !important;
}

</style>
