import Vue from 'vue'
import VeeValidate from 'vee-validate'
import Router from 'vue-router'
import store from './store'
import Home from './views/Home.vue'
import Results from './views/Results.vue'
import Results2 from './views/Results2.vue'
import ResultsMini from './views/ResultsMini.vue'
import Promotion from './views/Promotion.vue'
import ResultsAccordion from './views/ResultsAccordion.vue'

Vue.use(VeeValidate)
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: document
    .getElementById('quickstart-widget')
    .getAttribute('context-path'),
  routes: [
    {
      path: '/results',
      props: {},
      name: 'results',
      meta: {
        layout: 'default',
        page: 1,
        scrollToTop: true
      },
      component: Results
    },
    {
      path: '/resultsmini',
      props: {},
      name: 'resultsmini',
      meta: {
        layout: 'default',
        page: 1
      },
      component: ResultsMini
    },
    {
      path: '/results2',
      props: {},
      name: 'results2',
      meta: {
        layout: 'default',
        scrollToTop: true
      },
      component: Results2
    },
    {
      path: '/resultsaccordion',
      props: {},
      name: 'resultsaccordion',
      meta: {
        layout: 'default',
        scrollToTop: true
      },
      component: ResultsAccordion
    },
    {
      path: '/',
      props: {
        templateKey: document
          .getElementById('quickstart-widget')
          .getAttribute('template-key'),
        wizardStepColor: document
          .getElementById('quickstart-widget')
          .getAttribute('wizard-step-color'),
        wizardErrorColor: document
          .getElementById('quickstart-widget')
          .getAttribute('wizard-error-color')
      },
      name: 'home',
      meta: {
        layout: 'default',
        useBannerRight: document
          .getElementById('quickstart-widget')
          .getAttribute('use-banner-right'),
        page: 0
      },
      component: Home,
      beforeEnter: (to, from, next) => {
        if (store.state.initialView) {
          store.dispatch('toggleInitialView')
          if (typeof to.query.qs !== 'undefined') {
            if (typeof to.query.view !== 'undefined') {
              if (to.query.view === 'tool') {
                next()
              } else {
                let nextPath = '/' + to.query.view
                next({ path: nextPath, query: { qs: to.query.qs } })
              }
            } else {
              next({ path: '/results', query: { qs: to.query.qs } })
            }
          } else {
            next()
          }
        } else {
          next()
        }
      }
    },
    {
      path: '/promotion',
      name: 'promotion',
      meta: {
        layout: 'default',
        page: 2
      },
      component: Promotion
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition
    } else {
      const position = {}
      // new navigation.
      // scroll to anchor by returning the selector
      if (to.hash) {
        position.selector = to.hash

        // specify offset of the element
        if (to.hash === '#spending-container') {
          position.offset = { y: 100 }
        }
      }
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // cords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0
        position.y = 0
      }
      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return position
    }
  }
})
