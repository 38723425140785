<template>
  <div class="container" v-if="typeof(quickstartRequest.requestId) !== 'undefined' && quickstartRequest.requestId !== null">
    <form ref="form" @submit.prevent="onSubmit">
      <div class="inputshome">
        <div id="top-container" class="container columns is-desktop">
          <!-- <div id="promo-container" class="column">
            <div v-html="getContentSnippet('promo-content')">
            </div>
          </div> -->
        </div>
        <div class="container columns is-desktop" style="margin-bottom: 0px;">
          <div class="column is-full-desktop">
                  <demographics-inline ref="demographics" />
            </div>
        </div>
        <div id="input-container" class="container columns is-desktop">
            <div class="column is-one-third-desktop inputcol" style="padding-left: 0px;">
                <spending-input></spending-input>
            </div>
            <div class="column is-one-third-desktop inputcol">
              <savings v-show="getPlanType === null" ></savings>
              <savings-plan v-show="getPlanType !== null" ></savings-plan>
            </div>
            <div class="column is-one-third-desktop inputcol">
                <income />
            </div>
        </div>
      </div>
        <div class="container columns">
          <div class="column is-one-third"></div>
          <div class="column is-two-thirds" style="padding: 0;">
              <div id="calculate-button-container">
                <button :disabled="errors.any()" class="button is-success bigbutton">Calculate Your Retirement Strategy</button>
            </div>
          </div>
        </div>
    </form>
    <b-modal id="help-video" :active="youtubeModalActive" scroll="keep" :onCancel="() => this.youtubeModalActive = false">
      <div v-html="getContentSnippet('input-tutorial-1')">
      </div>
    </b-modal>
  </div>
</template>

<script>
import DemographicsInline from '@/components/DemographicsInline'
import Savings from '@/components/Savings'
import SavingsPlan from '@/components/SavingsPlan'
import Income from '@/components/Income'
import Spending from '@/components/SpendingSlider'
import SpendingInput from '@/components/SpendingInput'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import { mapGetters, mapActions } from 'vuex'
import { Tweet } from 'vue-tweet-embed'

const monthlyApiService = new MonthlyApiService()

export default {
  name: 'quickstartForm6',
  components: {
    DemographicsInline,
    Savings,
    SavingsPlan,
    Income,
    Spending,
    Tweet,
    SpendingInput
  },
  data () {
    return {
      submitted: false,
      helpSnackbarVisible: false,
      youtubeModalActive: false,
      videoId: 'NpEaa2P7qZI'
    }
  },
  watch: {
    helpSnackbarVisible: function (val) {
      if (val) {
        this.showHelpSnackbar()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getPlanType',
      'getUseGetMoreTeaser',
      'quickstartRequest',
      'resultsPage',
      'resultsAnchor'
    ])
  },
  methods: {
    ...mapActions({
      moveStep: 'moveStep',
      setGetMoreTeaserModalActive: 'setGetMoreTeaserModalActive'
    }),
    onSubmit () {
      this.moveStep('1')
      this.$tours['inputTour'].stop()
      monthlyApiService.startQuickstart().then((response) => {
        this.$store.dispatch('updateQuickstartRequestFromResponse', response.data.payload)
        monthlyApiService.startQuickOptimize().then((response) => {
          this.$store.dispatch('updateQuickstartResults', response.data.payload)
          if (this.$store.getters.getUseGetMoreTeaser) {
            this.setGetMoreTeaserModalActive(true)
          } else {
            if (this.resultsAnchor) {
              this.$router.push({ path: this.resultsPage, hash: '#' + this.resultsAnchor })
            } else {
              this.$router.push({ path: this.resultsPage })
            }
          }
        }
        )
      }
      )
    },
    showHelpSnackbar () {
      if (this.getPlanType === '401k' && !this.youtubeModalActive) {
        if (!this.$cookies.isKey('show_snackbar_count') ||
          parseInt(this.$cookies.get('show_snackbar_count')) <= 3) {
          this.$snackbar.open({
            message: 'Would you like to see a brief tutorial?',
            type: 'is-success',
            position: 'is-top',
            actionText: 'Yes',
            indefinite: false,
            duration: 30000,
            onAction: () => {
              this.youtubeModalActive = true
            }
          })
          let snackbarCount = (!this.$cookies.isKey('show_snackbar_count')) ? 1 : 1 + parseInt(this.$cookies.get('show_snackbar_count'))
          this.$cookies.set('show_snackbar_count', snackbarCount.toString())
        }
      }
    },
    playVideo () {
      this.$refs.player.player.playVideo()
    },
    pauseVideo () {

    }
  },
  mounted () {
    this.$store.dispatch('clearDirtyForm')
  },
  onIdle () {
    this.helpSnackbarVisible = true
  },
  onActive () {
    this.helpSnackbarVisible = false
  }
}
</script>

<style lang="scss" scoped>
$snackbar-background-color: #ffffff;

#top-container, #input-container {
  margin: 0 10px 10px 10px;
}
#input-container, #spending-container {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: 1px solid black;
}
#spending-container {
  margin:0 5px 0 0;
}
#promo-container {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  margin-left: 5px;
}
#calculate-button-container {
  padding: 0px 15px;
  text-align: right;
}

.banner-image > div > img {
    height: 350px;
}
#help-video .modal-content {
  overflow: hidden;
}

@media (min-width: 769px) {
  .has-right-border {
    border-right: 1px solid #dbdbdb;
  }
}

@media (max-width: 768px) {
  .has-right-border {
    border-bottom: 1px solid #dbdbdb;
  }
}
</style>
