<template>
  <div>
    <div class="columns">
      <div class="column">
        <longevity></longevity>
      </div>
    </div>
  </div>
</template>

<script>
import Longevity from '@/components/Longevity'

export default {
  data () {
    return {

    }
  },
  components: {
    Longevity
  }
}
</script>

<style scoped>

</style>
