<template>
  <div class="container">
    <nav class="navbar">
      <div class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item button is-primary is-medium btn-email-report" v-if="getMoreAmount > 0 && isInternalTool"
            @click="setDownloadReportModalActive(true)" style="margin-left: 45px; margin: 0px 0px 0px 40px;">
            Download retirement plan report
          </a>
          <a class="navbar-item button is-primary is-medium btn-email-report navbar-btn" v-if="getMoreAmount > 0  && !isInternalTool"
            @click="setEmailReportModalActive(true)" style="margin-left: 45px; margin: -0px 0px 0px 40px;">
            Send me a free personalized retirement plan report
          </a>
          <div v-for="link in links" v-bind:key="link.id">
              <router-link :to="link.page" v-show="link.id == getCurrentPage - 1" class="navbar-item button is-small is-link"><font-awesome-icon size="2x" icon="angle-left" />{{link.text}}</router-link>
          </div>
        </div>
        <div class="navbar-end">
          <div v-for="link in links" v-bind:key="link.id" >
              <router-link :to="link.page" v-if="getMoreAmount > -1 && link.id == getCurrentPage + 1" :class="{'is-hidden': getDirtyForm}" class="navbar-item button is-link">{{link.text}}<font-awesome-icon size="2x" icon="angle-right" /></router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      links: [
        {
          id: 0,
          text: 'Recalculate',
          page: '/home'
        },
        {
          id: 1,
          text: 'Your Results',
          page: '/results'
        },
        {
          id: 2,
          text: 'Learn More',
          page: '/promotion'
        }
      ]
    }
  },
  props: {
  },
  computed: {
    ...mapGetters([
      'getCurrentPage',
      'getMoreAmount',
      'getDirtyForm',
      'isInternalTool'
    ])
  },
  methods: {
    ...mapActions({
      setCurrentPage: 'setCurrentPage',
      setEmailReportModalActive: 'setEmailReportModalActive',
      setDownloadReportModalActive: 'setDownloadReportModalActive'
    })
  },
  mounted () {

  }
}
</script>

<style scoped>
.navbar-item.button .fa-angle-left {
  margin-right: 10px;
}
.navbar-item.button .fa-angle-right {
  margin-left: 10px;
}
#email-report-button {
  margin-right: 20px;
}
</style>
