<template>
  <div id="spending-result-container">
    <h2 class="subsectionheader">Retirement Spending</h2>
    <div class="columns">
      <div class="column">
        <div>
          <div id="spending-result-content" v-html="getContentSnippet('spending-result-content')">
          </div>
        </div>
        <p class="has-text-centered" style="margin-top:10px; padding-top:10px; border-top: 1px solid #e6e6e6;">
          Adjust your spending inputs to see how it will affect your plan.
          <button class="button is-secondary spendingadjust" @click="setSpendingEditMode" style="margin-top:10px">Adjust Spending Assumption</button>
        </p>
      </div>
      <div class="column">
        <div class="result-chart-container">
          <spending-scale customClass="spending-scale" :min="spendingScaleMin" :minLabel="spendingScaleMinLabel" :max="spendingScaleMax" :maxLabel="spendingScaleMaxLabel" :value="spending" :valueLabel="spendingLabel" :options="scaleOptions" title="Spending Rate" valueSuffix="/MONTH"></spending-scale>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SpendingScale from '@/components/VerticalScale'
export default {
  data () {
    return {
      scaleOptions: {
        layout: {
          height: 300
        },
        scale: {
          color: '#53cc04',
          width: 60
        }
      },
      spendingRate: null,
      minSpendingRate: null,
      maxSpendingRate: null
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'spending',
      'getDefaultSpendingRange',
      'getHouseholdTotalAssets'
    ]),
    spendingScaleMin () {
      return (this.getHouseholdTotalAssets * this.minSpendingRate) / 12
    },
    spendingScaleMinLabel () {
      return (this.minSpendingRate * 100).toFixed(0) + '%'
    },
    spendingScaleMax () {
      return (this.getHouseholdTotalAssets * this.maxSpendingRate) / 12
    },
    spendingScaleMaxLabel () {
      return (this.maxSpendingRate * 100).toFixed(0) + '%'
    },
    spendingLabel () {
      return (this.spendingRate * 100).toFixed(0) + '%'
    }
  },
  components: {
    SpendingScale
  },
  methods: {
    ...mapActions({
      setEditMode: 'setEditMode'
    }),
    setSpendingEditMode: function (ev) {
      this.setEditMode('spending')
      this.$router.push({ path: 'home' })
    }
  },
  mounted () {
    this.spendingRate = (this.spending * 12) / this.getHouseholdTotalAssets
    this.minSpendingRate = this.spendingRate * 0.5
    this.maxSpendingRate = this.spendingRate * 1.5
  }
}
</script>

<style lang="scss" scoped>
  #spending-result-content {
    margin-bottom: 15px;
  }
  .spendingadjust{
        border-radius: 20px;
    border: 2px solid #3a97f7;
    background-color: #e7f2ff;
    padding: 20px;
    font-weight: 600;
  }
</style>
