<template>
  <div class="container" id="learn-more-container">
    <div class="columns">
      <div class="column" v-html="getContentSnippet('learn-more-1')"></div>
    </div>
    <div class="columns">
      <div class="column" v-html="getContentSnippet('learn-more-2')">
      </div>
      <div class="column" v-html="getContentSnippet('learn-more-3')">
      </div>
    </div>
    <div class="columns">
      <div class="column" v-html="getContentSnippet('learn-more-4')"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'promotion',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'getMoreAmount',
      'getContentSnippet',
      'getContentSnippetNoHtml'
    ])
  },
  mounted () {
    this.$store.dispatch('setCurrentPage', this.$router.currentRoute.meta.page)
  }
}
</script>

<style scoped>

</style>
