import Vue from 'vue'
import VueCustomElement from 'vue-custom-element'
import VeeValidate from 'vee-validate'
import VueCookies from 'vue-cookies'
import VueTour from 'vue-tour'
import IdleVue from 'idle-vue'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueScrollTo from 'vue-scrollto'
import 'vue-tour/dist/vue-tour.css'
import App from './App.vue'
import Default from './layouts/Default.vue'
import router from './router'
import store from './store'
import Notifications from 'vue-notification'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import 'bulma-extensions/bulma-divider/dist/css/bulma-divider.min.css'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import Cleave from 'vue-cleave-component'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'document-register-element/build/document-register-element'
import Scrollmagic from 'scrollmagic'
import VueTimers from 'vue-timers'
import VueTooltip from 'v-tooltip'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import VueGtm from 'vue-gtm'

Vue.use(VueTour)

const monthlyApiService = new MonthlyApiService()
const apiKey = document.getElementById('quickstart-widget').getAttribute('api-key')
const internalTool = document.getElementById('quickstart-widget').getAttribute('internal')
const resultsPage = document.getElementById('quickstart-widget').getAttribute('results-page')
const resultsAnchor = document.getElementById('quickstart-widget').getAttribute('results-anchor')
const uid = document.getElementById('quickstart-widget').getAttribute('uid')
const eventsHub = new Vue()

library.add(faExclamationTriangle, faAngleLeft, faAngleRight, faArrowAltCircleUp)

Vue.use(VueCustomElement)
Vue.use(Buefy)
Vue.use(VueCookies)
Vue.use(VueTour)
Vue.use(IdleVue, { eventEmitter: eventsHub, store, idleTime: 40000 })
Vue.use(VueYouTubeEmbed)
Vue.use(VueScrollTo)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('default-layout', Default)
Vue.use(VeeValidate)
Vue.use(Notifications)
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})
Vue.use(Cleave)
Vue.use(Scrollmagic)
Vue.use(VueTimers)
Vue.use(VueTelInput)
Vue.use(VueTooltip)

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER
})

Vue.config.productionTip = false

const initialize = async () => {
  store.dispatch('initializeApiKey', apiKey)
  store.dispatch('setInternalTool', internalTool)
  store.dispatch('setInternalUserId', uid)
  store.dispatch('updateResultsPage', resultsPage)
  store.dispatch('updateResultsAnchor', resultsAnchor)

  await monthlyApiService.getQuickstartConfiguration()
    .then((response) => {
      store.dispatch('updateQuickstartConfiguration', response.data.payload)
        .then(() => {
          loadCosmicContent()
        })
    })

  await monthlyApiService.getAllocationStrategies()
    .then((response) => {
      store.dispatch('updateAllocationStrategies', response.data.payload)
    })

  // build Vue component after initial data is loaded

  build()
}

const loadCosmicContent = async () => {
  await store.dispatch('fetchContentSnippets', 'snippets')
  let subDomainSnippets = store.state.quickstartConfiguration.subdomain + 's'
  await store.dispatch('fetchSubDomainContentSnippets', subDomainSnippets)
  await store.dispatch('fetchContentImages')
  store.dispatch('updateCosmicLoadComplete', true)
}

function build () {
  App.store = store
  App.router = router

  Vue.customElement('quickstart-widget', App, {})
}

initialize()
