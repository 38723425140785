<template>
  <div id="other-income-result-container">
    <h2 class="subsectionheader">Retirement Income</h2>
    <div class="columns">
      <div class="column">
        <div>
          <div id="spending-result-content" v-html="getContentSnippet('other-income-result-content')">
          </div>
          <!-- <div id="income-list-container" class="has-text-centered">
                <ul>
                  <li>Part-time job</li>
                  <li>Pension</li>
                  <li>Inheritance</li>
                </ul>
              </div> -->
            <div id="additional-income-button-container">
              <button @click="setIncomeEditMode" class="button is-secondary incomeadjust">Add Additional Income</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet'
    ])
  },
  methods: {
    ...mapActions({
      setEditMode: 'setEditMode'
    }),
    setIncomeEditMode: function () {
      this.setEditMode('income')
      this.$router.push({ path: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  #income-list-container {
    width: 150px;
    border: 1px #ffc400 solid;
    border-radius: 3px;
    padding: 10px 0px;
  }
  #additional-income-button-container a {
    margin-top: 30px;
  }
  #additional-income-button-container{
    text-align: center;
  }
  .incomeadjust{
        border-radius: 20px;
    border: 2px solid #3a97f7;
    background-color: #e7f2ff;
    padding: 20px;
    font-weight: 600;
  }
</style>
